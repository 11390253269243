import React from 'react';
import './404.css'

function E404() {
    return ( 
        <>
        <div className='div-404'>
            <h1>ئەو لاپەڕەی بەدوای دەگەڕێی نەدۆزرایەوە</h1>
        </div>
        </>
     );
}

export default E404;