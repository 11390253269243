import React from 'react'
import './About.css'
import { FiMenu } from 'react-icons/fi';
import { AiFillPhone } from "@react-icons/all-files/ai/AiFillPhone";
import { IoLogoWhatsapp } from "@react-icons/all-files/io/IoLogoWhatsapp";
import { FaTelegram } from "@react-icons/all-files/fa/FaTelegram";
import { AiFillInstagram } from "@react-icons/all-files/ai/AiFillInstagram";
import {MdEmail} from "@react-icons/all-files/md/MdEmail";

export default function About() {
  function hidehefer(){
    document.getElementById('about').classList.toggle('show-header')
  }
  return (
    <div className='about' id='about'>
      <FiMenu className="main-page-menu" onClick={()=>hidehefer()}/>
      <div className='about-text'>
        <h1>ئێمە کێین؟</h1>
        <p>ئێمە تیمێکی کوردین بریتی لە چەندین زانای نەوت و زانای بواری کۆمپیۆتەر</p>
        <p>ئامانجی ئێمە خزمەتە بە گەلی کوردە تا  بە زمانی کوردی زانستە جیهانیەکان لەبەر دەسی هەموان بێت</p>
        <p>بەو هیوایە ئێمەش وەک گەلانی تر بتوانین بە زمانی خۆمان زانستەکانمان لەبەر دەست بێت</p>
        <p>هیوا خوازین ئێوەش لەم ئیشەدا یارمەتی دەری ئێمە بن</p>
        <p>بۆ پەیوەندی لەگەڵ ئێمە دەتوانن پەیوەندی بکەن به :</p>
        
      </div>
      <div className="pagethird-logo-box">
        <a href="tel:+9647517217694"><AiFillPhone className="pagethird-logo"/></a>
        <a href="https://wa.me/9647517217694"><IoLogoWhatsapp className="pagethird-logo"/></a>
        <a href="https://t.me/petromk_com"><FaTelegram className="pagethird-logo"/></a>
        {/* <a href="https://www.instagram.com/soran.ghafoorii"><AiFillInstagram className="pagethird-logo"/></a> */}
        <a href="mailto:petromk.com@gmail.com"><MdEmail className="pagethird-logo"/></a>
      </div>
    </div>
    
  )
}
