import React ,{useEffect, useState}from "react";
import "./Main.css";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { BiDollarCircle } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { BsPersonVideo3 } from "react-icons/bs";
import { LiaLanguageSolid } from "react-icons/lia";
import { MdOutlineSupportAgent } from "react-icons/md";
import CourseCard from "../CourseCard/CourseCard";
import { GiOilPump } from "react-icons/gi";
import { NavLink } from 'react-router-dom'
import BookCard1 from '../BookCard/BookCard1'
import User from "../User/User";
import {FiMenu} from 'react-icons/fi'
import { get } from "stream-http";

function Main() {
  const [books , setBooks] = useState()
  const [loading,setLoading] = useState(true)
  const courses = [{ icon: <GiOilPump/>, title: "کۆرسی هەڵکۆڵین", time: "٢٤:٣٥:٠٠", price: "٠" },
    ];


    function hidehefer(){
      document.getElementById('header').classList.toggle('show-header')
    }
    async function GetBooks(){
      console.log("salkjdfn")
      const response =await axios.get('https://petromk.com/api/admin/book7')
      
      setBooks(response.data)
      setLoading(false)
    }
    useEffect(()=>{
      GetBooks()
    },[])

    
    const users=[{name:'سۆران غەفووری' , job:'ئەندازیاری نەوت' , witness:'ماستەری نەوتی زانکۆی تاران' ,honors:'نووسەری کتێبی نەوت لە کوێوە هاتوووه ' , now:'دامەزراوی پاڵاوگەی تیشک'}, 
     ];
  return (
    <div className="main-page">
      <FiMenu className="main-page-menu" onClick={()=>hidehefer()}/>
      <div className="main-page1">
        <div className="main-page-left">
          <h1>ماڵپەڕی پەتڕۆماڵی کوردستان </h1>
          <p>
            شوێنێک بۆسەرجەم زانسته نەوتیەکان ، کۆرسی فێربونن ، ئاگاداری
            دامەزراندن و پرسیار و وڵام{" "}
          </p>
          <div className="main-page-left-button">
            <NavLink to="/courses"><button>کۆرسەکان</button></NavLink>
            <NavLink to="/book"><button style={{backgroundColor:'#fff' , color:'#CF4E1F'}}>پەرتووکەکان</button></NavLink>
            
            
          </div>
        </div>
        <div className="main-page-right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="109.779 -296.125 550.738 343.173"
          >
            <defs>
              <clipPath id="clip1">
                <path d="M0 0h1125v1125H0zm0 0"></path>
              </clipPath>
              <filter
                id="alpha"
                width="100%"
                height="100%"
                x="0%"
                y="0%"
                filterUnits="objectBoundingBox"
              >
                <feColorMatrix
                  in="SourceGraphic"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                ></feColorMatrix>
              </filter>
              <mask id="mask0">
                <g filter="url(#alpha)">
                  <path
                    fill="#FFF"
                    fillOpacity="0.7"
                    d="M0 0H1125V1125H0z"
                  ></path>
                </g>
              </mask>
              <clipPath id="clip2">
                <path d="M0 0H1125V1125H0z"></path>
              </clipPath>
              <g clipPath="url(#clip2)">
                <path
                  fill="#FFF"
                  d="M518.809 103.621c-12.91-28.305-32.41-52.988-61.22-66.785-38.487-18.438-84.116-18.895-124.046-4.758-22.785 8.055-42.984 21.602-62.61 35.41-17.453 12.27-37.359 31.012-58.937 34.973-29.203 5.367-98.203-38.133-149.953-26.133-51.75 12-66.75 79.5-54 121.5s62.25 42 80.25 76.5 82.5 63.75 162 44.25 120.75-30.75 162.75-15 99.75 20.25 119.25-45.758c7.805-26.422 10.078-54.449 6.39-81.761-3.234-23.934-9.37-49.391-19.874-72.438zm0 0"
                ></path>
              </g>
              <view viewBox="107.011 -297.458 550.738 344.096"></view>
            </defs>
            <g clipPath="url(#clip1)" transform="translate(106.05 -297.74)">
              <path
                fill="#F4F5F7"
                d="M0 0h1125v1125H0zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                d="M515.059 97.621c-12.91-28.305-32.41-52.988-61.22-66.785-38.487-18.438-84.116-18.895-124.046-4.758-22.785 8.055-42.984 21.602-62.61 35.41-17.453 12.27-37.359 31.012-58.937 34.973-29.203 5.367-98.203-38.133-149.953-26.133-51.75 12-66.75 79.5-54 121.5s62.25 42 80.25 76.5 82.5 63.75 162 44.25 120.75-30.75 162.75-15 99.75 20.25 119.25-45.758c7.805-26.422 10.078-54.449 6.39-81.761-3.234-23.934-9.37-49.391-19.874-72.438zm0 0"
                paintOrder="fill"
              ></path>
              <g clipPath="url(#clip2)" mask="url(#mask0)">
                <path
                  fill="#FFF"
                  d="M518.809 103.621c-12.91-28.305-32.41-52.988-61.22-66.785-38.487-18.438-84.116-18.895-124.046-4.758-22.785 8.055-42.984 21.602-62.61 35.41-17.453 12.27-37.359 31.012-58.937 34.973-29.203 5.367-98.203-38.133-149.953-26.133-51.75 12-66.75 79.5-54 121.5s62.25 42 80.25 76.5 82.5 63.75 162 44.25 120.75-30.75 162.75-15 99.75 20.25 119.25-45.758c7.805-26.422 10.078-54.449 6.39-81.761-3.234-23.934-9.37-49.391-19.874-72.438zm0 0"
                  paintOrder="fill"
                ></path>
              </g>
              <path
                fill="#263238"
                d="M538.605 114.414v-15.75h-19.183V5.027h-11.707v93.637H488.05v-23.96c5.472-1.231 9.57-6.114 9.57-11.958 0-6.77-5.492-12.262-12.266-12.262-6.77 0-12.261 5.493-12.261 12.262 0 6.16 4.554 11.25 10.469 12.121v23.797H260.625v-5.512H248.91v5.512H113.38v-5.512h-11.715v5.512H79.418V62.06h7.187v-6.97h7.805V42.82h-7.805v-6.976h-7.187v-16.59h4.957V7.539h-4.957V.004h-15.75v7.535h-4.95v11.715h4.95v87.285a7.875 7.875 0 007.875 7.875h30.121v42.816H66.527v-5.511H54.812v5.511H32.567v-36.605h7.184v-6.977h7.816v-12.27H39.75v-6.964h-7.184V77.816h4.957V66.102h-4.957V.566h-15.75v65.54h-4.949v11.71h4.95v87.29a7.877 7.877 0 007.874 7.874h30.122v153.356h11.714V172.98h35.137v153.61h11.715V172.98h88.68v153.625h11.714V172.98h35.137v153.188h11.715V172.98h247.09v4.399h11.707v-4.399h17.851v-15.75h-17.851v-42.816h19.183M213.773 157.23v-5.511H202.06v5.511h-88.68v-42.816H248.91v42.816h-35.137m293.942 0h-247.09v-42.816h247.09v42.816zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#FFF"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M485.656 63.047l2.992 5.387M494.328 184.18a8.67 8.67 0 01-8.668 8.668c-4.793 0-8.68-3.883-8.68-8.668a8.68 8.68 0 018.68-8.68c4.785 0 8.668 3.887 8.668 8.68zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#F4F5F7"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M275.605 2.305c0-.043.028-.075.075-.075h50.129c.039 0 .074.032.074.075v323.988c0 .035-.035.074-.074.074h-50.13a.074.074 0 01-.074-.074zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M319.246 55.906v50.887M318.715 27.82v-3.715"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M330.055 45.43a4.691 4.691 0 01-4.692 4.695 4.694 4.694 0 01-4.703-4.695 4.693 4.693 0 014.703-4.703 4.69 4.69 0 014.692 4.703zm0 0M317.754 45.43a4.695 4.695 0 01-4.695 4.695 4.696 4.696 0 01-4.704-4.695 4.695 4.695 0 014.704-4.703 4.694 4.694 0 014.695 4.703zm0 0M305.45 45.43a4.692 4.692 0 01-4.696 4.695 4.693 4.693 0 01-4.7-4.695 4.692 4.692 0 014.7-4.703 4.691 4.691 0 014.695 4.703zm0 0M293.148 45.43a4.695 4.695 0 01-4.695 4.695 4.696 4.696 0 01-4.703-4.695 4.695 4.695 0 014.703-4.703 4.694 4.694 0 014.695 4.703zm0 0M280.844 45.43a4.692 4.692 0 01-4.696 4.695 4.696 4.696 0 01-4.703-4.695 4.695 4.695 0 014.703-4.703 4.691 4.691 0 014.696 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M325.36 40.727a4.705 4.705 0 01-4.704-4.704 4.701 4.701 0 014.703-4.695 4.695 4.695 0 014.696 4.695 4.699 4.699 0 01-4.696 4.704zm0 0M313.05 40.727a4.699 4.699 0 01-4.695-4.704 4.695 4.695 0 014.696-4.695 4.699 4.699 0 110 9.399zm0 0M300.75 40.727a4.702 4.702 0 01-4.7-4.704 4.698 4.698 0 014.7-4.695 4.699 4.699 0 110 9.399zm0 0M288.453 40.727a4.699 4.699 0 110-9.398 4.695 4.695 0 014.695 4.694 4.699 4.699 0 01-4.695 4.704zm0 0M276.145 40.727a4.699 4.699 0 01-4.696-4.704 4.695 4.695 0 014.696-4.695 4.701 4.701 0 014.703 4.695 4.705 4.705 0 01-4.703 4.704zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M331.883 40.418h-61.918"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M325.36 154.426a4.705 4.705 0 01-4.704-4.7 4.699 4.699 0 014.703-4.695 4.698 4.698 0 010 9.395zm0 0M313.05 154.426a4.698 4.698 0 010-9.395 4.696 4.696 0 110 9.395zm0 0M300.75 154.426a4.701 4.701 0 01-4.7-4.7 4.695 4.695 0 014.7-4.695 4.696 4.696 0 110 9.395zm0 0M288.453 154.426a4.696 4.696 0 110-9.395 4.698 4.698 0 010 9.395zm0 0M276.145 154.426a4.698 4.698 0 010-9.395 4.699 4.699 0 014.703 4.696c0 2.593-2.11 4.699-4.703 4.699zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M330.055 140.332a4.688 4.688 0 01-4.692 4.691 4.69 4.69 0 01-4.703-4.691 4.693 4.693 0 014.703-4.703 4.69 4.69 0 014.692 4.703zm0 0M317.754 140.332a4.691 4.691 0 01-4.695 4.691 4.693 4.693 0 01-4.704-4.691 4.695 4.695 0 014.704-4.703 4.694 4.694 0 014.695 4.703zm0 0M305.45 140.332a4.689 4.689 0 01-4.696 4.691 4.69 4.69 0 01-4.7-4.691 4.692 4.692 0 014.7-4.703 4.691 4.691 0 014.695 4.703zm0 0M293.148 140.332a4.691 4.691 0 01-4.695 4.691 4.693 4.693 0 01-4.703-4.691 4.695 4.695 0 014.703-4.703 4.694 4.694 0 014.695 4.703zm0 0M280.844 140.332a4.689 4.689 0 01-4.696 4.691 4.693 4.693 0 01-4.703-4.691 4.695 4.695 0 014.703-4.703 4.691 4.691 0 014.696 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M331.883 144.723h-61.918"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M330.055 290.84a4.689 4.689 0 01-4.692 4.695 4.691 4.691 0 01-4.703-4.695 4.695 4.695 0 014.703-4.703 4.693 4.693 0 014.692 4.703zm0 0M317.754 290.84a4.692 4.692 0 01-4.695 4.695 4.694 4.694 0 01-4.704-4.695 4.698 4.698 0 014.704-4.703 4.696 4.696 0 014.695 4.703zm0 0M305.45 290.84a4.69 4.69 0 01-4.696 4.695 4.69 4.69 0 01-4.7-4.695 4.695 4.695 0 014.7-4.703 4.694 4.694 0 014.695 4.703zm0 0M293.148 290.84a4.692 4.692 0 01-4.695 4.695 4.694 4.694 0 01-4.703-4.695 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M280.844 290.84a4.69 4.69 0 01-4.696 4.695 4.694 4.694 0 01-4.703-4.695 4.698 4.698 0 014.703-4.703 4.694 4.694 0 014.696 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M325.36 286.133a4.698 4.698 0 01-4.704-4.692 4.699 4.699 0 119.398 0 4.691 4.691 0 01-4.695 4.692zm0 0M313.05 286.133a4.691 4.691 0 01-4.695-4.692 4.699 4.699 0 119.399 0 4.695 4.695 0 01-4.703 4.692zm0 0M300.75 286.133a4.695 4.695 0 01-4.7-4.692 4.702 4.702 0 119.403 0 4.695 4.695 0 01-4.703 4.692zm0 0M288.453 286.133a4.695 4.695 0 01-4.703-4.692 4.699 4.699 0 119.398 0 4.691 4.691 0 01-4.695 4.692zm0 0M276.145 286.133a4.691 4.691 0 01-4.696-4.692 4.699 4.699 0 119.399 0 4.698 4.698 0 01-4.703 4.692zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M331.883 285.836h-61.918"
                paintOrder="fill"
              ></path>
              <path
                fill="#F4F5F7"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M223.504 1.875c0-.047.031-.078.074-.078h12.824c.04 0 .078.031.078.078v324.148c0 .04-.039.075-.078.075h-12.824a.074.074 0 01-.074-.075zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M224.027 111.957a1.39 1.39 0 11-2.78.008 1.39 1.39 0 012.78-.008zm0 0M227.664 111.957a1.39 1.39 0 11-2.78.008 1.39 1.39 0 012.78-.008zm0 0M231.305 111.957a1.393 1.393 0 11-2.785 0 1.39 1.39 0 011.39-1.387c.77 0 1.395.621 1.395 1.387zm0 0M234.941 111.957a1.39 1.39 0 11-2.78.008 1.39 1.39 0 012.78-.008zm0 0M238.578 111.957a1.39 1.39 0 11-2.78.008 1.39 1.39 0 012.78-.008zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M224.027 114.738a1.391 1.391 0 11-2.781 0 1.39 1.39 0 112.781 0zm0 0M227.664 114.738a1.391 1.391 0 11-2.781 0 1.39 1.39 0 112.781 0zm0 0M231.305 114.738c0 .77-.625 1.395-1.395 1.395a1.394 1.394 0 111.395-1.395zm0 0M234.941 114.738a1.391 1.391 0 11-2.781 0 1.39 1.39 0 112.781 0zm0 0M238.578 114.738a1.391 1.391 0 11-2.781 0 1.39 1.39 0 112.781 0zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M220.703 113.441h18.309"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M224.027 35.996a1.39 1.39 0 11-2.78.008 1.39 1.39 0 012.78-.008zm0 0M227.664 35.996a1.39 1.39 0 11-2.78.008 1.39 1.39 0 012.78-.008zm0 0M231.305 35.996a1.393 1.393 0 11-2.785 0 1.39 1.39 0 011.39-1.387c.77 0 1.395.621 1.395 1.387zm0 0M234.941 35.996a1.39 1.39 0 11-2.78.008 1.39 1.39 0 012.78-.008zm0 0M238.578 35.996a1.39 1.39 0 11-2.78.008 1.39 1.39 0 012.78-.008zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M224.027 38.777a1.39 1.39 0 11-2.78.009 1.39 1.39 0 012.78-.009zm0 0M227.664 38.777a1.39 1.39 0 11-2.78.009 1.39 1.39 0 012.78-.009zm0 0M231.305 38.777a1.393 1.393 0 11-2.785 0 1.39 1.39 0 011.39-1.386c.77 0 1.395.62 1.395 1.386zm0 0M234.941 38.777a1.39 1.39 0 11-2.78.009 1.39 1.39 0 012.78-.009zm0 0M238.578 38.777a1.39 1.39 0 11-2.78.009 1.39 1.39 0 012.78-.009zm0 0M409.273 315.203c0-.043.028-.074.075-.074h3.359c.043 0 .074.031.074.074v18.285c0 .047-.031.078-.074.078h-3.36c-.046 0-.074-.03-.074-.078zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M220.703 37.48h18.309"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M224.027 142.57a1.39 1.39 0 11-2.78.009 1.39 1.39 0 012.78-.009zm0 0M227.664 142.57a1.39 1.39 0 11-2.78.009 1.39 1.39 0 012.78-.009zm0 0M231.305 142.57a1.393 1.393 0 11-2.785 0 1.39 1.39 0 011.39-1.386c.77 0 1.395.62 1.395 1.386zm0 0M234.941 142.57a1.39 1.39 0 11-2.78.009 1.39 1.39 0 012.78-.009zm0 0M238.578 142.57a1.39 1.39 0 11-2.78.009 1.39 1.39 0 012.78-.009zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M224.027 145.348c0 .773-.62 1.398-1.394 1.398a1.392 1.392 0 010-2.785c.773 0 1.394.625 1.394 1.387zm0 0M227.664 145.348c0 .773-.621 1.398-1.394 1.398a1.392 1.392 0 010-2.785c.773 0 1.394.625 1.394 1.387zm0 0M231.305 145.348c0 .773-.625 1.398-1.395 1.398a1.393 1.393 0 111.395-1.398zm0 0M234.941 145.348c0 .773-.62 1.398-1.394 1.398a1.392 1.392 0 010-2.785c.773 0 1.394.625 1.394 1.387zm0 0M238.578 145.348c0 .773-.621 1.398-1.394 1.398a1.392 1.392 0 010-2.785c.773 0 1.394.625 1.394 1.387zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M220.703 144.055h18.309"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M239.79 78.086c0 10.95-6.294 19.828-14.063 19.828-7.77 0-14.063-8.879-14.063-19.828 0-10.953 6.293-19.824 14.063-19.824 7.77 0 14.062 8.87 14.062 19.824zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M235.5 78.086c0 10.95-6.293 19.828-14.062 19.828-7.77 0-14.063-8.879-14.063-19.828 0-10.953 6.293-19.824 14.063-19.824 7.769 0 14.062 8.87 14.062 19.824zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M219.691 67.09h-7.418v7.305a15.238 15.238 0 00-.496 3.847c0 1.352.184 2.64.496 3.84v7.305h7.418c4.368 0 7.907-4.989 7.907-11.145s-3.54-11.152-7.907-11.152zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M220.184 78.238c0 6.157-3.54 11.153-7.914 11.153-4.364 0-7.903-4.996-7.903-11.153 0-6.16 3.54-11.144 7.903-11.144 4.375 0 7.914 4.984 7.914 11.144zm0 0M448.883 163.945c0-.047.027-.074.074-.074h17.07c.043 0 .075.027.075.074v24.793c0 .04-.032.078-.075.078h-17.07c-.047 0-.074-.039-.074-.078zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M439.379 154.441c0-.043.031-.074.074-.074h34.89c.04 0 .075.031.075.074v21.829c0 .035-.035.074-.074.074h-34.89a.077.077 0 01-.075-.074zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M476.64 142.492c0 10.738-8.706 19.446-19.445 19.446-10.742 0-19.449-8.708-19.449-19.446 0-10.742 8.707-19.449 19.45-19.449 10.738 0 19.445 8.707 19.445 19.45zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeMiterlimit="10"hse
                strokeWidth="3"
                d="M486.887 142.492c0 16.395-13.297 29.692-29.692 29.692-16.394 0-29.691-13.297-29.691-29.692 0-16.394 13.297-29.695 29.691-29.695 16.395 0 29.692 13.3 29.692 29.695zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="3"
                d="M462.04 142.488a4.842 4.842 0 10-9.685.005 4.842 4.842 0 009.684-.005zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="3"
                d="M457.195 112.793v58.793M427.5 142.488h59.387"
                paintOrder="fill"
              ></path>
              <path
                fill="#F4F5F7"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M3.625 184.254h475.91c34.285 0 62.168 27.887 62.168 62.168v79.941h-50.25v-79.941c0-6.578-5.348-11.918-11.918-11.918H3.625"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M226.531 229.54h12.985M176.246 229.54h46.957M401.125 229.54h12.98M280.48 229.54h117.313"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M442.133 186.43a4.705 4.705 0 01-4.7 4.703 4.699 4.699 0 01-4.695-4.703 4.698 4.698 0 019.395 0zm0 0M442.133 198.738a4.698 4.698 0 01-9.395 0 4.696 4.696 0 119.395 0zm0 0M442.133 211.04c0 2.593-2.106 4.698-4.7 4.698a4.695 4.695 0 01-4.695-4.699 4.69 4.69 0 014.696-4.695c2.593 0 4.699 2.097 4.699 4.695zm0 0M442.133 223.344a4.695 4.695 0 01-4.7 4.695 4.69 4.69 0 01-4.695-4.695 4.699 4.699 0 014.696-4.703c2.593 0 4.699 2.109 4.699 4.703zm0 0M442.133 235.645a4.705 4.705 0 01-4.7 4.703 4.699 4.699 0 01-4.695-4.703 4.699 4.699 0 014.696-4.704c2.593 0 4.699 2.11 4.699 4.704zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M432.734 186.438a4.695 4.695 0 01-4.695 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.704 4.696 4.696 0 014.695 4.704zm0 0M432.734 198.738a4.692 4.692 0 01-4.695 4.696 4.694 4.694 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M432.734 211.043a4.695 4.695 0 01-4.695 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M432.734 223.344a4.692 4.692 0 01-4.695 4.695 4.694 4.694 0 01-4.703-4.695c0-2.602 2.101-4.7 4.703-4.7a4.693 4.693 0 014.695 4.7zm0 0M432.734 235.648a4.695 4.695 0 01-4.695 4.696 4.696 4.696 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M426.7 179.758c0-.043.027-.074.073-.074h11.73c.036 0 .075.03.075.074v62.207c0 .035-.039.074-.074.074h-11.73a.074.074 0 01-.075-.074zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M432.43 179.906v61.918"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M487.871 186.438a4.695 4.695 0 01-4.695 4.695 4.694 4.694 0 01-4.703-4.695 4.695 4.695 0 014.703-4.704 4.696 4.696 0 014.695 4.704zm0 0M487.871 198.738a4.692 4.692 0 01-4.695 4.696 4.691 4.691 0 01-4.703-4.696 4.695 4.695 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M487.871 211.043a4.695 4.695 0 01-4.695 4.695 4.694 4.694 0 01-4.703-4.695 4.695 4.695 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M487.871 223.344a4.692 4.692 0 01-4.695 4.695 4.691 4.691 0 01-4.703-4.695c0-2.602 2.097-4.7 4.703-4.7a4.693 4.693 0 014.695 4.7zm0 0M487.871 235.648a4.695 4.695 0 01-4.695 4.696 4.694 4.694 0 01-4.703-4.696 4.695 4.695 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M478.473 186.43a4.708 4.708 0 01-4.703 4.703 4.701 4.701 0 01-4.696-4.703 4.695 4.695 0 014.696-4.696 4.701 4.701 0 014.703 4.696zm0 0M478.473 198.738a4.701 4.701 0 01-4.703 4.696 4.695 4.695 0 01-4.696-4.696 4.699 4.699 0 119.399 0zm0 0M478.473 211.04c0 2.593-2.11 4.698-4.703 4.698a4.698 4.698 0 010-9.394 4.699 4.699 0 014.703 4.695zm0 0M478.473 223.344a4.699 4.699 0 01-4.703 4.695 4.692 4.692 0 01-4.696-4.695 4.701 4.701 0 014.696-4.703 4.708 4.708 0 014.703 4.703zm0 0M478.473 235.645a4.708 4.708 0 01-4.703 4.703 4.701 4.701 0 01-4.696-4.703 4.701 4.701 0 014.696-4.704 4.708 4.708 0 014.703 4.704zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M472.434 179.758c0-.043.03-.074.074-.074h11.73c.04 0 .075.03.075.074v62.207c0 .035-.036.074-.075.074h-11.73a.077.077 0 01-.074-.074zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M478.172 179.906v61.918"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M269.785 186.438a4.692 4.692 0 01-4.695 4.695 4.693 4.693 0 01-4.7-4.695 4.695 4.695 0 014.7-4.704 4.694 4.694 0 014.695 4.704zm0 0M269.785 198.738a4.69 4.69 0 01-4.695 4.696 4.69 4.69 0 01-4.7-4.696 4.695 4.695 0 014.7-4.703 4.694 4.694 0 014.695 4.703zm0 0M269.785 211.043a4.692 4.692 0 01-4.695 4.695 4.693 4.693 0 01-4.7-4.695 4.695 4.695 0 014.7-4.703 4.694 4.694 0 014.695 4.703zm0 0M269.785 223.344a4.69 4.69 0 01-4.695 4.695 4.69 4.69 0 01-4.7-4.695c0-2.602 2.098-4.7 4.7-4.7a4.69 4.69 0 014.695 4.7zm0 0M269.785 235.648a4.692 4.692 0 01-4.695 4.696 4.693 4.693 0 01-4.7-4.696 4.695 4.695 0 014.7-4.703 4.694 4.694 0 014.695 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M260.387 186.43a4.701 4.701 0 01-4.696 4.703 4.708 4.708 0 01-4.703-4.703 4.701 4.701 0 014.703-4.696 4.695 4.695 0 014.696 4.696zm0 0M260.387 198.738a4.695 4.695 0 01-4.696 4.696 4.701 4.701 0 01-4.703-4.696 4.699 4.699 0 119.398 0zm0 0M260.387 211.04c0 2.593-2.102 4.698-4.696 4.698a4.705 4.705 0 01-4.703-4.699 4.699 4.699 0 014.703-4.695 4.692 4.692 0 014.696 4.695zm0 0M260.387 223.344a4.692 4.692 0 01-4.696 4.695 4.699 4.699 0 01-4.703-4.695 4.708 4.708 0 014.703-4.703 4.701 4.701 0 014.696 4.703zm0 0M260.387 235.645a4.701 4.701 0 01-4.696 4.703 4.708 4.708 0 01-4.703-4.703 4.708 4.708 0 014.703-4.704 4.701 4.701 0 014.696 4.704zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M254.352 179.758c0-.043.03-.074.074-.074h11.73c.04 0 .074.03.074.074v62.207c0 .035-.035.074-.074.074h-11.73a.077.077 0 01-.074-.074zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M260.086 179.906v61.918"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M103.762 186.438a4.695 4.695 0 01-4.696 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.704 4.696 4.696 0 014.696 4.704zm0 0M103.762 198.738a4.692 4.692 0 01-4.696 4.696 4.694 4.694 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.696 4.703zm0 0M103.762 211.043a4.695 4.695 0 01-4.696 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.696 4.703zm0 0M103.762 223.344a4.692 4.692 0 01-4.696 4.695 4.694 4.694 0 01-4.703-4.695c0-2.602 2.102-4.7 4.703-4.7a4.693 4.693 0 014.696 4.7zm0 0M103.762 235.648a4.695 4.695 0 01-4.696 4.696 4.696 4.696 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.696 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M94.363 186.438a4.695 4.695 0 01-4.695 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.704 4.696 4.696 0 014.695 4.704zm0 0M94.363 198.738a4.692 4.692 0 01-4.695 4.696 4.694 4.694 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M94.363 211.043a4.695 4.695 0 01-4.695 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M94.363 223.344a4.692 4.692 0 01-4.695 4.695 4.694 4.694 0 01-4.703-4.695c0-2.602 2.101-4.7 4.703-4.7a4.693 4.693 0 014.695 4.7zm0 0M94.363 235.648a4.695 4.695 0 01-4.695 4.696 4.696 4.696 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M88.324 179.758c0-.043.031-.074.078-.074h11.73c.036 0 .075.03.075.074v62.207c0 .035-.039.074-.074.074h-11.73a.078.078 0 01-.079-.074zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M94.059 179.906v61.918"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M545.938 283.297a4.692 4.692 0 01-4.696 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.703 4.694 4.694 0 014.696 4.703zm0 0M533.637 283.297a4.695 4.695 0 01-4.696 4.695 4.694 4.694 0 01-4.703-4.695 4.695 4.695 0 014.703-4.703 4.696 4.696 0 014.696 4.703zm0 0M521.332 283.297a4.695 4.695 0 01-4.695 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M509.027 283.297a4.692 4.692 0 01-4.695 4.695 4.693 4.693 0 01-4.7-4.695 4.695 4.695 0 014.7-4.703 4.694 4.694 0 014.695 4.703zm0 0M496.727 283.297a4.695 4.695 0 01-4.696 4.695 4.696 4.696 0 01-4.703-4.695 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.696 4.703zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M545.938 273.898a4.69 4.69 0 01-4.696 4.696 4.694 4.694 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.694 4.694 0 014.696 4.703zm0 0M533.637 273.898a4.692 4.692 0 01-4.696 4.696 4.691 4.691 0 01-4.703-4.696 4.695 4.695 0 014.703-4.703 4.696 4.696 0 014.696 4.703zm0 0M521.332 273.898a4.692 4.692 0 01-4.695 4.696 4.694 4.694 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.695 4.703zm0 0M509.027 273.898a4.69 4.69 0 01-4.695 4.696 4.69 4.69 0 01-4.7-4.696 4.695 4.695 0 014.7-4.703 4.694 4.694 0 014.695 4.703zm0 0M496.727 273.898a4.692 4.692 0 01-4.696 4.696 4.694 4.694 0 01-4.703-4.696 4.698 4.698 0 014.703-4.703 4.696 4.696 0 014.696 4.703zm0 0M1325.832 247.395c0-.047.031-.075.074-.075h11.73c.04 0 .079.028.079.075v62.203c0 .039-.04.074-.078.074h-11.73a.074.074 0 01-.075-.074zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M547.766 278.29h-61.922"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M462.098 326.297h82.98M20.23 326.297h435.735"
                paintOrder="fill"
              ></path>
              <path
                fill="#C23706"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M377.844 124.367l-6.489 19.45-2.398.238-1.441 2.398-12.48-9.117v20.406l16.808 8.88s4.082-1.438 5.761-2.4c1.68-.96 4.56-43.933 4.56-43.933l-4.321 4.078zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#F4F5F7"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M418.3 318.07s4.509 14.227 4.325 16.567c-.18 2.347-.898 2.886-2.16 3.246-1.266.363-10.086-.36-10.086-.36l-.54-18.375 8.462-1.078zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M422.625 334.637v-.219c-3.852.195-9.93.559-12.313.7l.067 2.398s8.82.722 10.086.367c1.262-.367 1.98-.906 2.16-3.246zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M414.52 300.242s.359 6.309 2.52 11.348c2.16 5.039 2.16 6.48 1.081 7.558-1.082 1.09-5.941 1.801-7.922 2.168-1.98.36-1.441-5.945-1.441-9.906 0-3.96 4.32-12.246 5.762-11.168zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#F4F5F7"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M390.75 314.828s-4.324 6.129-6.848 8.29c-2.52 2.16-6.476 4.679-10.797 7.378-4.328 2.7-4.148 2.7-3.789 4.5.36 1.8 2.887 3.43 5.227 3.43h37.098s2.879-1.09 1.98-7.387c-.902-6.3-1.8-14.95-1.8-14.95l-21.071-1.26zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M413.55 335.574c-7.19.106-31.175.422-36.667-.035-3.043-.25-5.73-1.023-7.723-1.773-.031.332.05.707.156 1.23.36 1.8 2.887 3.43 5.227 3.43h37.098s1.347-.512 1.91-2.852zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M382.164 103.96s-3.601 11.528-4.32 18.247c-.719 6.719-4.801 41.535-6 47.3-1.207 5.763 1.199 6.243 1.199 6.243s.242 15.848-.48 22.566c-.72 6.727 2.402 41.543 4.082 56.91 1.68 15.368 6 19.45 6.96 23.528.957 4.082 7.688 22.566 7.688 23.289 0 .719 2.398 5.766 1.68 6.965-.723 1.203-3.121.242-3.121 2.883 0 2.64-.239 4.8-.239 4.8s10.797 3.606 14.887 4.325c4.078.722 6.719.242 6.719.242s1.441-1.922 2.879-6.25c1.441-4.317 1.68-7.918 1.441-11.278-.238-3.367-.96-9.367-.96-11.289v-9.367c0-2.879.241-10.32.241-10.32s3.84-42.262 4.078-44.902c.243-2.641.961-6.72 1.68-8.399.723-1.68 5.29-18.25 5.047-26.176-.238-7.922-1.2-16.086-1.2-16.086s1.923-4.8 1.442-12.96c-.48-8.168-.96-22.098-.96-22.817 0-.719 1.198-24.488-3.368-34.336-4.559-9.84-16.328-15.605-16.328-15.605l-23.047 12.488zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M412.148 95.957c-3.007.113-9.496 1.426-15.336 9.922-7.921 11.527-9.359 24.969-9.359 26.41 0 1.445-2.648 45.86-2.648 45.86l7.687 2.16s.238-36.735 3.36-51.621c3.12-14.887 7.449-21.368 12.007-24.489 3.239-2.219 7.559-2.855 9.793-3.043-1.746-2.035-3.68-3.773-5.504-5.199zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M386.973 134.215l-10.328-1.68-1.2 10.559 11.528 1.922zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#C23706"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M384.563 97.96s-7.2 4.798-7.68 6.24c-.48 1.44-1.68 9.609-1.68 9.609l10.32-6.73-.96-9.118zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M366.266 68.934s-1.036 4.097-.782 4.855c.246.75 2.977 3.832 2.977 3.832s-3.57 5.742-2.563 7.5c1.004 1.762 3.516 1.258 3.516 1.258s2.504 9.285 5.52 10.035c3.007.758 5.273-1.754 5.273-1.754l5.266 7.531 17.07-14.558-2.004-7.531s2.004-6.524 2.004-12.051c0-5.52-.75-7.278-.75-7.278l-36.031 6.91.504 1.25zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M401.793 60.773l-22.188 5.356-.156.668s0 2.512 1.508 3.766c1.508 1.253 4.266 1.253 4.266 1.253s0 3.262 2.257 2.008c2.258-1.254 0-4.265 3.766-4.77 3.766-.503 5.527 2.512 4.524 6.024-1.004 3.516-2.762 4.77-2.762 4.77s5.02 1.257 7.531.254c0 0 2.004-6.524 2.004-12.051 0-5.52-.75-7.278-.75-7.278zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                d="M372.375 76.203c.363.973.34 1.883-.05 2.024-.391.144-.997-.532-1.36-1.508-.36-.973-.336-1.883.055-2.024.39-.144.996.532 1.355 1.508zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M371.305 78.754s1.132 1.984 5.105 1.566"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M369.414 86.379s-.383.61-.383 2.168c0 1.566.992 2.414 2.13 2.414 1.132 0 6.534 1.57 6.952-.848.43-2.414-1.273-2.984-3.547-4.402-2.273-1.418-4.777.625-5.152.668zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M362.117 64.336s0 1.09-1.8 3.969c-1.801 2.879-5.758 6.48-5.758 6.48s15.117-.719 19.445-2.879c4.32-2.16 9.719-4.68 11.52-5.043 1.8-.36 19.09-4.324 19.09-4.324l-2.884-4.684-3.492-11.57c-.789-2.601-2.843-4.644-5.468-5.363-3.504-.953-8.739-1.899-14.086-1.07-9.368 1.437-15.848 6.476-16.926 11.527-1.082 5.039.36 12.957.36 12.957zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#C23706"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M402.332 83.793s-21.848 20.168-22.328 20.887c-.48.718 5.031 9.96 5.031 9.96l23.305-23.41-6.008-7.437zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M346.023 125.54s-2.632-.196-2.91 1.413c-.277 1.613 1.875 6.274 3.598 6.625 1.726.352 2.07-.398 1.93-1.148-.145-.75-2.618-6.89-2.618-6.89zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M349.195 124.691s-2.55-.675-3.12.852c-.579 1.531.667 6.504 2.296 7.18 1.625.668 2.106 0 2.106-.766s-1.282-7.266-1.282-7.266zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M352.059 126.453s-1.32-3.3-2.864-1.758c-1.539 1.54.223 5.5.883 7.035.66 1.547 2.422 1.989 2.422-.875 0-2.859-.441-4.402-.441-4.402zm0 0M354.488 127.074s-2.293 4.59-1.527 6.121c.762 1.532 2.008.766 3.066-.765 1.051-1.532 2.48-4.305 2-6.121-.468-1.82-2.675-.957-3.539.765zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M353.535 117.895a23.173 23.173 0 00-.598 5.023l-.066 8.559.762-.098.691-8.363a23.558 23.558 0 00-.031-4.133l-.098-.942a.367.367 0 00-.66-.046zm0 0h0zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M359.355 145.254l-4.796-15.848-21.856-1.918 11.766 30.016 19.687 4.316zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M339.863 128.402l1.98 3.825 6.997.398-1.317-3.562-1.98-.399-.398-1.184 1.582.262v-1.453l-7.125-.266.398 1.32h2.242v1.188zm0 0M371.207 154.18s-3.969-3.301-4.184-3.961c-.218-.66-1.757-2.196-3.086-3.742-1.316-1.536-5.496-3.516-5.496-3.516l1.54 4.836s-5.942-1.754-7.262-1.754c-1.32 0-1.32 1.32-1.102 3.516.223 2.199 2.422 3.082 2.422 3.082s0 .66.66 2.859c.66 2.203.88 4.184 3.082 5.504 2.2 1.32 3.52 1.32 5.496 1.32 1.989 0 3.97-.441 3.97-.441l4.179 1.101-.219-8.804zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M407.379 116.898s-2.16 2.88-3.121 7.438c-.965 4.562-1.446 27.855-1.446 27.855s-2.878-.48-3.12.48c-.239.962-.481 2.161-.481 2.161l-29.047-2.16-.96 14.16s22.327 4.086 30.487 5.29c8.168 1.198 15.368 2.636 18.489-.243 3.129-2.887 6.007-23.293 6.968-30.496.957-7.207 3.118-19.207 1.438-26.414-1.68-7.2-6.961-10.32-9.367-10.559"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M399.934 155.102s0 2.878 3.359 7.199M404.02 154.383s.48.48 3.601 1.199M422.258 177.91s-4.078 1.68-14.16 1.68M425.148 193.996s-5.527 1.2-10.09 1.442l-4.558.238s1.68 15.129 2.64 18.008c.958 2.882 4.798 3.601 4.798 3.601M380.395 210.559c1.07 5.722 2.257 12.293 2.73 15.855.96 7.2 0 24.965 1.2 32.895 1.198 7.918 3.609 22.086 4.808 27.613 1.199 5.52 3.597 17.289 3.597 17.289l.243 4.797M378.324 199.758s.594 3.008 1.371 7.094"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M212.535 64.465l-4.11 11.3-7.804.82.207 2.466 7.188.414 6.574 6.98 1.847-1.226-4.93-8.633 2.876-11.297zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M212.125 77.102c0 2.437-1.191 4.41-2.672 4.41-1.476 0-2.668-1.973-2.668-4.41 0-2.446 1.192-4.418 2.668-4.418 1.48 0 2.672 1.972 2.672 4.418zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M209.559 59.125c-6.864 0-12.426 7.906-12.426 17.664 0 9.754 5.562 17.668 12.426 17.668 6.863 0 12.43-7.914 12.43-17.664 0-9.754-5.567-17.668-12.43-17.668zm0 28.965c-4.395 0-7.95-5.055-7.95-11.297 0-6.238 3.555-11.3 7.95-11.3 4.386 0 7.949 5.062 7.949 11.3 0 6.234-3.563 11.297-7.95 11.297zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M188.363 79.418s4.008-8.496 4.5-9.492c.504-1.008 5.508-7.758 6.258-8.508.75-.75 4.379-.238 4.379-.238s-2.633 3.238-2.879 3.988c-.258.75 1.746 5.754-1.008 9-2.742 3.258-7.5 11.25-7.5 11.25l-3.75-6zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M196.613 68.918l4.008-3.75s7.5-2.496 7.5-.996-2.25 2.754-4.008 3.996c-1.746 1.254-3.492 2.25-3.492 2.25s1.746 4.5-2.508 6.75c-4.242 2.25-5.496 2.25-5.496 2.25"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M148.613 102.668l15.258 2.25 23.742-27.246 8.004 9s-19.254 29.004-21.246 33.246c-2.004 4.254-3.75 5.004-6.25 5.508-2.504.492-16.5-2.004-16.5-2.004l-3.008-20.754zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M177.121 90.176l10.5 8.25-4.004 6.75-11.496-9.258zm0 0M152.523 62.508s2.735 3.156 2.848 3.93c.113.78-.922 4.71-.922 4.71s5.707 3.485 5.602 5.48c-.106 2.005-2.543 2.673-2.543 2.673s1.922 9.32-.403 11.324c-2.328 2.004-6.226 1.86-6.226 1.86l-.516 7.93-21.55-5.25-1.575-7.548s-4.68-4.867-7.14-9.742c-2.457-4.875-2.57-6.758-2.57-6.758l34.874-9.945.121 1.336zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M117.527 71.117l21.97-5.144.433.515s1.117 2.223.343 4c-.773 1.778-3.21 3.008-3.21 3.008s1.457 2.879-1.094 2.774c-2.559-.098-1.906-3.766-5.453-2.536-3.547 1.239-3.758 4.672-1.305 7.336 2.453 2.657 4.562 2.977 4.562 2.977s-3.87 3.344-6.535 3.578c0 0-4.68-4.867-7.14-9.742-2.457-4.883-2.57-6.766-2.57-6.766zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M154.133 56.605s.488.954 3.355 2.692c2.871 1.742 7.977 3.156 7.977 3.156s-13.68 6.098-18.457 6.113c-4.778.024-10.664.204-12.418.684-1.75.488-18.774 4.68-18.774 4.68l.457-5.414-2.062-11.77c-.465-2.644.441-5.367 2.437-7.168 2.672-2.41 6.872-5.566 11.965-7.223 8.903-2.894 16.875-1.328 20.07 2.641 3.2 3.973 5.45 11.61 5.45 11.61zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M133.621 74.164l14.469-1.285-.317-3.149-15.214 3.676zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#263238"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M147.129 71.531h0m0 0a2.55 2.55 0 002.363 1.934l6.399.3a1.235 1.235 0 001.289-1.386l.113-.258a4.082 4.082 0 00-.547-4.176l-.129-.156a1.233 1.233 0 00-1.558-1.043l-6.13 1.695a2.555 2.555 0 00-1.8 3.09zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#F4F5F7"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M124.121 320.168s-1.254 8.25-1 12.504c.25 4.254 1 5.004 1 5.004h36.492s0-5.004-2.742-7.5c-2.754-2.508-6.75-5.004-9.258-6.504-2.496-1.5-8.25-5.496-8.25-5.496l-16.242 1.992zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M132.371 250.418s.246 16.254-1.75 23.25c-2 7.008-7.25 20.508-7.5 29.754-.254 9.246-.5 20.25.25 20.25s2.25.75 3.996.504c1.746-.258 6.996-.504 6.996-.504l3-.504v-12.246c0-11.004-3.492-20.25-3-29.754.504-9.492 5.508-15.75 3-24.996-2.496-9.246-4.992-9-4.992-5.754zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#F4F5F7"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M158.363 319.418s4.758 5.004 7.008 6c2.25 1.008 11.25 4.5 12 5.004.75.504 4.5 2.496 4.5 4.5s-.75 3.504-.75 3.504h-45.258s-.996-1.758-.75-5.004c.258-3.246 1.008-10.5 1.008-10.5s4.5-5.004 10.242-6c5.754-.996 12 2.496 12 2.496zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M135.863 338.426h45.258s.563-1.14.711-2.754h-46.687c.187 1.793.718 2.754.718 2.754zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M125.867 97.676s-6.996 7.746-11.004 16.992c-3.996 9.258-3.246 27.504-1.746 33 1.5 5.508 3.996 12 3.504 18.258-.504 6.246-2.754 9.996-1.254 12.75 1.5 2.742 3 4.746 3 4.746s-3 8.496-.996 16.254c2 7.746 4.25 15.75 7.746 17.496l3.504 1.746s1.746 19.758 3.75 31.5c2 11.754 2.25 20.754-1.5 29.754s.75 23.25 1.746 27.504c.996 4.242 1.746 9.996 1.746 13.242 0 3.258-2.496 3.75.75 5.754 3.258 2.004 4.008 2.496 6.258 1.254 2.25-1.254 12.246-5.754 13.992-6 1.758-.258 4.5 1.242 5.508-2.508.996-3.75-1.5-9.75-2.004-11.25s-2.496-6.246-2.496-8.496 2-24.996 6-35.25c3.996-10.254 3.996-24.246 3.996-32.496 0-8.25 2.754-23.004 3-24.504s3-15.996 3.246-26.004c.258-9.996-.996-18.996-2.742-25.5-1.758-6.492-3-13.5-3-13.5s-6.75-26.742-11.004-31.992-3.504-4.5-9.996-7.008c-6.504-2.496-20.004-5.742-20.004-5.742zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M120.863 103.922s7.254-2.25 18.504-1.5c11.25.75 15.996 7.5 18.246 10.746 2.25 3.258 8.25 33.258 9.258 42.75.996 9.504.75 15 .75 15l-3.504 2.004s-.504-15-2.25-27.996c-1.746-13.008-5.004-27.75-11.25-34.008-6.246-6.246-14.496-6.246-18.75-5.742-4.254.492-14.754 5.496-14.754 5.496l3.75-6.75zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M126.184 91.922l25.875 7.5 3.187 9.375-31.125-10.5zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="none"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M143.363 136.676s-9 3.492-16.746 5.496M148.121 135.926s-7.758 5.496-17.25 12M130.223 155.223c-2.164 1.746-4.297 3.261-6.102 4.199M143.621 142.676s-5.68 6.015-11.59 11.047M149.484 165.281c1.426-1.773 2.664-3.406 3.383-4.605M131.867 177.926s6.504.492 9-2.508c1.238-1.484 3.637-4.191 6.024-7.004M135.73 180.34c2.91-.406 5.52-.945 6.883-1.664 4.254-2.25 8.004-6.75 8.004-7.5M122.117 181.418s5.098-.156 10.328-.688M141.188 202.547c.292 4.074.503 10.305-.825 11.625-1.992 2.004-8.742 2.004-8.742 2.004M140.867 198.926s.074.613.164 1.613M149.867 176.672s8.496-1.746 12.504-4.254M157.121 82.172s-3.258.996-4.254-.246M165.293 192.773c-.75-2.421-1.422-4.101-1.422-4.101M166.613 222.668s.75-12.75.75-18.996c0-2.383-.5-5.129-1.133-7.621"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M209.363 98.168s-.246-4.5 0-6.75c.258-2.25 2.754-6 3.258-8.742.492-2.754-.504-5.25 1.5-5.25 1.992 0 1.242 4.992 1.242 4.992s3.504-3.246 5.004-4.746c1.5-1.5 2.754-2.004 3.504-.504s0 3 0 3 1.25 3.258 1.746 5.004c.504 1.746.996 3.996-.75 7.254-1.746 3.246-2.496 5.25-4.746 6.492-2.25 1.254-5.5 4.5-6 5.25-.504.75-1.5 2.004-1.5 2.004l-3.258-8.004zm0 0"
                paintOrder="fill"
              ></path>
              <path
                fill="#CF4E1F"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M117.469 111.52s5.004-8.497 15.996-7.747c11.004.75 18.258 6.504 19.254 6.997.996.503 20.496 9.257 20.496 9.257l34.758-25.5 7.242 10.243s-27.996 27.503-30.996 30.75c-3 3.257-8.25 5.507-9.996 5.753-1.75.247-31.5-5.003-34.254-5.25-2.754-.246-15.996-3.75-15.996-3.75"
                paintOrder="fill"
              ></path>
              <path
                fill="#FFF"
                stroke="#263238"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
                d="M188.621 111.422l10.5 12.75 5-5.25-9-11.754zm0 0"
                paintOrder="fill"
              ></path>
            </g>
          </svg>
        </div>
      </div>

      <div className="main-page1-boxlogo-logo">
        {/* <div>
          <div>
            <BiDollarCircle className="main-page1-boxlogo-logoo" />
          </div><p>نرخێکی گونجاو</p>
        </div> */}
        <div>
          <div>
            <FiUsers className="main-page1-boxlogo-logoo" />
          </div><p>پرسیار لە نێوان ئەندامان</p>
        </div>
        <div>
          <div>
            <BsPersonVideo3 className="main-page1-boxlogo-logoo" />
          </div><p>باشترین مامۆستایان</p>
        </div>
        <div>
          <div>
            <LiaLanguageSolid className="main-page1-boxlogo-logoo" />
          </div><p>بە زمانی کوردی</p>
        </div>
        <div>
          <div>
            <MdOutlineSupportAgent className="main-page1-boxlogo-logoo" />
          </div><p>یارمەتی دانی ٢٤ کاتژمێر</p>
        </div>
      </div>

      <div className="main-page2">
        <div className="main-page2-box">
        <div className="main-page2-box-right">
          <div><h1>نوێترین کۆرسەکان</h1>
          <h4>فێرکاری ببینه ، فێرببه ، ببه ئەندازیاری نەوت </h4></div>
          
          <NavLink to="/courses"><Button style={{color:'',background:'#CF4E1F',border:'none',fontSize:'20px'}} >هەموویان ببینه </Button></NavLink>
          
          </div>
          <div className="main-page2-box-left">
          {courses.map((course)=>(
            <CourseCard icon={course.icon} title={course.title} time={course.time} price={course.price}/>
          ))}
        </div>
        </div>



        {/* <div className="main-page2-right">
          <Carousel>
            <Carousel.Item interval={3000}>
              <Card style={{ flexDirection: "row" }}>
                <Card.Img
                  variant="top"
                  src="/11.jpg"
                  style={{ width: "400px", height: "400px" }}
                />
                <Card.Body>
                  <Card.Title>پەرتووکەکان</Card.Title>
                  <Card.Text>
                    ئەمه پەرتووکه نوێیە سەبارەت به:
                    <br />
                    نوسراوی:
                    <br />
                    لە ساڵی:
                    <br />
                    زۆر به کەڵک دێت بۆ تەماته سوورۆ کردن
                  </Card.Text>
                  <Button variant="primary">بینینی پەرتووک</Button>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item interval={3000}>
              <Card style={{ flexDirection: "row" }}>
                <Card.Img
                  variant="top"
                  src="12.jpg"
                  style={{ width: "400px", height: "400px" }}
                />
                <Card.Body>
                  <Card.Title>پەرتووکەکان</Card.Title>
                  <Card.Text>
                    ئەمه پەرتووکه نوێیە سەبارەت به:
                    <br />
                    نوسراوی:
                    <br />
                    لە ساڵی:
                    <br />
                    زۆر به کەڵک دێت بۆ تەماته سوورۆ کردن
                  </Card.Text>
                  <Button variant="primary">بینینی پەرتووک</Button>
                </Card.Body>
              </Card>
            </Carousel.Item>
           
          </Carousel>
        </div> */}
      </div>
      <div className="main-page2">
        <div className="main-page2-box">
        <div className="main-page2-box-right">
          <div><h1>نوێترین کتێبەکان</h1>
          <h4>هەموو کتێبە کوردی<br/> و ئینگلیزییەکان<br/></h4></div>
          
          <NavLink to="/book"><Button style={{color:'',background:'#CF4E1F',border:'none',fontSize:'20px'}}>هەموویان ببینه </Button></NavLink>
          
          </div>
          {loading?(<h1>loading</h1>):(
            <div className="main-page2-box-left">
          {books.map((book)=>(
            <BookCard1 book={book} style={{}}/>
            
          ))}
        </div>
          )}
          
        </div>
        </div>
        <div className="main-page2">
        <div className="main-page2-box">
        <div className="main-page2-box-right">
          <div><h1>ئەندازیارانی نەوت</h1>
          <h4>ئەندازیارانی کورد لێره ببینه<br/> زانیاری زیاتر بەدەست بێنه له کۆمپانیا نەوتییەکان<br/></h4></div>
          
          <NavLink to="/users"><Button style={{color:'',background:'#CF4E1F',border:'none',fontSize:'20px'}}>هەموویان ببینه </Button></NavLink>
          
          </div>
          <div className="main-page2-box-left">
          {users.map((user)=>(
            <User name={user.name} job={user.name}  witness={user.witness} honors={user.honors} now={user.now}/>
          ))}
        </div>
        </div>
        </div>
    </div>
  
  );
}

export default Main;