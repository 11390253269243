import React from 'react'
import './CourseDrilling.css'
import CoursePage from '../CoursePage/CoursePage'


export default function CourseDrilling() {
  return (
    <>
    <CoursePage />
    </>
    
  )
}
