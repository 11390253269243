import "./BookListPage.css";
import axios from "axios";
import { useState, useEffect } from "react";
import BookCard from "../../BookCard/BookCard";
import { useParams } from "react-router-dom";
import {FiMenu} from 'react-icons/fi'

function BookListPage() {
  const [books, setBooks] = useState();
  const [loading, setLoading] = useState(true);
  const { name } = useParams();

  function hidehefer(){
    document.getElementById('booklistpage').classList.toggle('show-header')
  }
  function getData() {
    axios.get(`https://petromk.com/api/admin/book/${name}`).then((respnose) => {
      setBooks(respnose?.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  const dataBookList = {
    English: `
        ئینگلیزی تەکنیکی لە پیشەسازی نەوتدا زۆر گرنگە بۆ پەیوەندی کاریگەر لە نێوان ئەو کەسە پیشەییانەی کە بەشدارن لە گەڕان و کونکردن و بەرهەمهێنان و پاڵاوتن و دابەشکردنی نەوت و گاز. ئەم فۆرمە تایبەتمەندەی زمانی ئینگلیزی زاراوە و زاراوە و چەمکە تایبەتەکان بە ئەندازیاری نەوت و بوارە پەیوەندیدارەکان لەخۆدەگرێت.
لایەنێکی بنەڕەتی ئەندازیاری خەزانەکانە، کە مامەڵە لەگەڵ تایبەتمەندی و بەڕێوەبردنی کۆگاکانی ژێرزەوی دەکات کە هایدرۆکاربۆنیان تێدایە. ئەندازیاران ئینگلیزی تەکنیکی بەکاردەهێنن بۆ گفتوگۆکردن لەسەر پارامێتەرەکانی وەکو کونیلەیی، ڕژان، پەستانی خەزنەکە، و تایبەتمەندییەکانی شلە بۆ باشکردنی ستراتیژییەکانی بەرهەمهێنان.
هەروەها کارەکانی کونکردن و تەواوکردن زۆر پشت بە ئینگلیزی تەکنیکی دەبەستن. پیشەگەرەکان زاراوەی وەکو دریل بیت، کەیسینگ، چیمەنتۆکردن و ڕێڕەوی بیربۆر بەکاردەهێنن بۆ وەسفکردنی قۆناغە جیاوازەکانی کونکردن، دڵنیابوون لە دەرهێنانی سەلامەت و کارامەی هایدرۆکاربۆنەکان لە توێکڵی زەوی.
لە ئەندازیاری بەرهەمهێناندا ئینگلیزی تەکنیکی بەکاردێت بۆ وەسفکردنی سیستەمی بەرزکردنەوەی دەستکرد، وەک پەمپ، بەرزکەرەوەی غاز، و ESP (Electric Submersible Pumps)، کە ڕۆیشتنی نەوت و گاز لە بیرەکانەوە بۆ سەر ڕووی زەوی بەرز دەکەنەوە.
پرۆسەکانی پاڵاوتنی کارەکانی یەکە ئاڵۆزەکانی وەکو تەقاندنەوە، کاتالیزەکردن و هایدرۆپرۆسێسکردن لەخۆدەگرن، پێویستی بە زمانێکی تەکنیکی ورد هەیە بۆ گفتوگۆکردن لەسەر ئامێرەکانی وەک ڕیاکتۆر، گەرماگۆڕەکان و ستوونی بەشکردن.
سەرەڕای ئەوەش، گواستنەوەی بۆری پێویستی بە پەیوەندییەکی ڕوون هەیە سەبارەت بە دروستکردن، کارپێکردن و چاککردنەوەی بۆری، کە زاراوەی وەک بەرازکردن، پاراستنی کاتۆدی و دڵنیایی لێشاوی تێدایە.
هەروەها ئابووری و دارایی نەوت وشەسازی تەکنیکی تایبەت بە خۆیان هەیە، لەوانە زاراوەی وەک بەهای ئێستای پاک (NPV)، ڕێژەی گەڕانەوەی ناوخۆیی (IRR)، و ڕێککەوتنەکانی هاوبەشکردنی بەرهەمهێنان (PSAs)، کە زۆر گرنگن بۆ بڕیارەکانی وەبەرهێنان و هەڵسەنگاندنی پڕۆژەکان.
ڕەچاوکردنی ژینگە و سەلامەتی لە پیشەسازی نەوتدا لە پێش هەموو شتێکەوەیە، پیشەییەکان ئینگلیزی تەکنیکی بەکاردەهێنن بۆ گفتوگۆکردن لەسەر بابەتەکانی وەک ڕێگریکردن لە تەقینەوە، هەڵسەنگاندنی کاریگەرییە ژینگەییەکان و پابەندبوون بە ڕێساکان.
بە گشتی، شارەزابوون لە زمانی ئینگلیزی تەکنیکی لە پیشەسازی نەوتدا زۆر گرنگە بۆ هاوکاری کاریگەر، بەڵگەنامەکردنی ورد، و جێبەجێکردنی سەرکەوتووانەی پڕۆژە لە سەرانسەری هەموو قۆناغەکانی زنجیرە بەهای نەوت و گازدا.
        `,
    Electrical: `
        کارەبا بەردی بناغەی کارەکانی پاڵاوگەی مۆدێرن و پترۆکیمیاییە، هەموو لایەنەکانی بەرهەمهێنان دەگرێتەوە. هێز دەدات بە ئامێرە سەرەکییەکان، لە پەمپ و کۆمپرێسەرەوە تا گەرمکەرەوە و ماتۆڕەکان، ئەمەش دڵنیای دەدات لە کارکردنی بەردەوام و ڕۆیشتنی بەرهەم. لە دەرەوەی تەنها دابینکردنی کارەبا، کارەبا ئاسانکاری دەکات بۆ کۆنترۆڵکردن و چاودێریکردنی ئاڵۆز لە ڕێگەی ئامێر و سیستەمی کۆنترۆڵەوە، ڕێکخستنی پارامێتەرەکانی وەک پلەی گەرمی و پەستان بۆ کارایی گونجاو و کوالیتی بەرهەم.
کارەبا هەر لە بەرهەم هێنانیەوە لە ناو یەکەکانی یووتیلیتی هەتا شوێنی بڵاوبوونەوەیان واتە ساب ستەیشن و ترەی کێبڵ و ڕاکێشانی کێبڵ و ترەیسینگی کارەبایی و ڕووناکایی و ئلکترۆمۆتۆر و ئاگادارکردنەوەی ئەیر کڕافت و  جاکشن باکس و سیستەمی ئێرت و پارێزەری کاتۆدی هەموویان دەکەونە بەشی کارەباوە
        `,
    Drilling: `
        کونکردن لە پیشەسازی نەوت و گازدا پرۆسەی دروستکردنی بیرە بۆ دەستگەیشتن بە یەدەگی هایدرۆکاربۆنی ژێر زەوی. ئامێر و تەکنیکە ئاڵۆزەکانی تێدایە بۆ چوونە ناو چینەکانی بەرد و نیشتوو لە ژێر ڕووی زەویدا. ئامێری کونکردن کە بەپێی پێکهاتەی جیاواز دروستکراوە، کون دەکاتە ناو زەوی، بە جوڵەی سووڕاوە کاردەکات.
بە درێژایی کونکردنەکە، شلەی تایبەتمەند کە پێیان دەوترێت قوڕی کونکردن دەسووڕێنەوە بۆ ساردکردنەوەی کونکردنەکە و گواستنەوەی بەردە بڕاوەکان بۆ سەر ڕووی بیرەکە و جێگیرکردنی کونی بیرەکە. لەگەڵ پێشکەوتنی کونکردنەکەدا، کەیسینگ دەخرێتە ناو بیرەکەوە بۆ بەهێزکردنی، و چیمەنتۆ بە دەوری کەیسینگەکەدا پەمپ دەکرێت بۆ ئەوەی لە شوێنی خۆیدا پارێزراو بێت و ڕێگری لە کۆچی شلە بکات.
تەکنیکەکانی کونکردنی ئاراستەیی ڕێگە بە بەڕێوەبەران دەدەن کە بە شێوەی ئاسۆیی یان بە گۆشەی بیرەکە بڕۆن، ئەمەش باشترکردنی دەستڕاگەیشتن بە کۆگاکان. کاتێک کونکردنەکە تەواو بوو، بیرەکە پرۆسەی تەواوکردنی تێدەپەڕێت، لەوانە کونکردن و دانانی ئامێری بەرهەمهێنان، بۆ دەرهێنانی هایدرۆکاربۆن.
ڕەچاوکردنی ژینگەیی لە کارەکانی کونکردندا لە پێش هەموو شتێکەوەیە، لەگەڵ ڕێوشوێنەکان بۆ کەمکردنەوەی کاریگەرییە ئیکۆلۆژییەکان و دڵنیابوون لە پابەندبوون بە ڕێساکان. لە گەڕانەوە تا بەرهەمهێنان، کونکردن هەنگاوێکی بنەڕەتییە لە دەرهێنانی سەرچاوەی نەوت و گاز، کە دابینکردنی وزەی جیهانی بەهێز دەکات
        `,
    Process: `
        پرۆسەی کیمیایی بڕبڕەی پشتی هەردوو کارەکانی پاڵاوگە و پترۆکیمیایین، بە شێوەیەکی ئاڵۆز لە هەموو قۆناغێکی بەرهەمهێناندا تواوەتەوە. ئەم پرۆسانە کۆمەڵێک کارلێک و گۆڕانکاری بەرفراوان لەخۆدەگرن کە مادە خاوەکانی وەک نەوتی خاو و غازی سروشتی دەگۆڕن بۆ بەرهەمی کۆتایی بەنرخ کە لە پیشەسازییە جیاوازەکاندا بەکاردەهێنرێن.
لە پاڵاوگەکاندا، پرۆسەی کیمیایی بە پلەی یەکەم لەسەر جیاکردنەوە و گۆڕینی نەوتی خاو بۆ بەرهەمی نەوتی پاڵاوتە وەک بەنزین و گازۆیل و سووتەمەنی جێت چڕ دەبێتەوە. تەقاندنی بەشەکی، درزکردن، چاکسازی و ئەلکیلکردن لە پرۆسە سەرەکییەکانن کە بەکاردەهێنرێن بۆ شکاندنی مۆلیکولە ئاڵۆزەکانی هایدرۆکاربۆن، ڕێکخستنەوەی پێکهاتەکانیان و بەرزکردنەوەی کوالیتی و بەهای بەرهەمە دەرەنجامییەکان.
لە کارگە پترۆکیمیاییەکاندا، جەختکردنەوەکە دەگۆڕێت بۆ بەرهەمهێنانی ماددە کیمیاییەکان و بەرهەمە کۆتاییەکان کە لە دروستکردنی پلاستیک و پۆلیمر و ڕیشاڵ و کەرەستەی دیکەدا بەکاردەهێنرێن. پرۆسەکانی وەک ستیم کرەکینگ، ڕیفۆرمینگ، پۆلیمرکردن و هایدرۆجینکردن بەکاردەهێنرێن بۆ گۆڕینی ماددە سەرەکییەکانی هایدرۆکاربۆن بۆ ئیتیلێن، پرۆپیلین، بێنزێن و...
کاتالیزە ڕۆڵێکی گرنگ دەگێڕێت لە هەردوو پرۆسەی پاڵاوگە و پترۆکیمیاییدا، ئاسانکاری بۆ کارلێکەکان دەکات  ئەگەرنا زۆر بە هێواشی یان ناکارامە بەرەوپێش دەچن. کاتالیستەکان ڕێگە بە باشکردنی بارودۆخی پرۆسەکە دەدەن، کە دەبێتە هۆی باشترکردنی بەرهەم، هەڵبژاردن و کارایی وزە.
سەرەڕای ئەوەش، پرۆسەی کیمیایی چارەسەری جۆراوجۆر و هەنگاوی پاککردنەوە لەخۆدەگرێت کە ئامانجیان لابردنی پیسی، بەرزکردنەوەی سەرچاوە سەرەکییەکان، و دڵنیابوونە لە کوالیتی بەرهەم و پابەندبوون بە ستانداردە ڕێکخراوەییەکان. ئەسید تریئەیتمن، هایدرۆجینکردن، هایدرۆتریئەیتینگ و تەکنیکەکانی جیاکردنەوە بە شێوەیەکی باو بۆ پاڵاوتن و پاککردنەوەی  هایدرۆکاربۆن بەکاردەهێنرێن.
ڕەچاوکردنی ژینگەیی لە پرۆسەی کیمیاییدا یەکدەخرێت، بە گرنگیدان بە کەمکردنەوەی دەردانی گازی ژەهراوی، کەمکردنەوەی بەرهەمهێنانی پاشماوە و بەرزکردنەوەی بەردەوامیی. تەکنەلۆژیا و پراکتیزە پێشکەوتووەکان، وەک گرتن، بەکارهێنان و هەڵگرتنی کاربۆن (CCUS)، کەمکردنەوەی پاشماوە، و شێوازەکانی بەرهەمهێنانی پاکتر، بەردەوام پەرەپێدەدرێن و جێبەجێ دەکرێن بۆ کەمکردنەوەی کاریگەرییە ژینگەییەکان و پێشخستنی بەڕێوەبردنی بەرپرسیارانەی سەرچاوەکان.
توێژینەوە و داهێنان دەبێتە هۆی پێشکەوتنی بەردەوام لە پاڵاوگە و کیمیای پترۆکیمیایی، گەڕان بەدوای کاتالیست و پرۆسێس و ماددە سەرەکییە نوێیەکان بۆ بەرزکردنەوەی کارایی، کێبڕکێ و بەردەوامیی لە دیمەنێکی جیهانیدا کە هەمیشە لە گەشەکردندایە.
بە کورتی، پرۆسەی کیمیایی خوێنی ژیانی پاڵاوگە و کارەکانی پترۆکیمیایین، کە دەبێتە هۆی گۆڕینی کەرەستەی خاو بۆ بەرهەمی بەنرخ لە هەمان کاتدا هاوسەنگی لە نێوان ڕەچاوکردنی ئابووری و ژینگەیی و کۆمەڵایەتیدا هەیە.
        `,
    Exploration: `
        گەڕان بەدوای نەوتدا هەوڵێکی ستراتیژییە کە سەرنجی لەسەر دۆزینەوە و هەڵسەنگاندنی کۆگاکانی ژێرزەوییە (نەوتی خاو و گازی سروشتییە). بوارێکی فرە پسپۆڕییە کە شارەزایی جیۆلۆجی و جیۆفیزیکی و ئەندازیاری تێکەڵ دەکات بۆ دیاریکردنی ناوچە بەڵێندەرەکان بۆ کونکردن.
شیکاری جیۆلۆجی بنەڕەتییە، کە بریتییە لە لێکۆڵینەوە لە پێکهاتەکانی ڕووکار و حەوزی نیشتوو و تایبەتمەندییە پێکهاتەییەکان بۆ تێگەیشتن لە مێژووی جیۆلۆجی و ئەگەری کەڵەکەبوونی هایدرۆکاربۆن.
تەکنیکە جیۆفیزیکییەکان، بە تایبەتی ڕووپێوی بوومەلەرزەزانی، تێڕوانینێکی گرنگ لەبارەی پێکهاتەی ژێرزەویەوە دەدەن. بە دروستکردن و شیکردنەوەی شەپۆلی بوومەلەرزەزانی، جیۆفیزیکزانان دەتوانن نەخشەی چینەکانی بەردی ژێرزەوی بکەن و تەڵەی ئەگەری دەستنیشان بکەن کە لەوانەیە هایدرۆکاربۆنەکان تێیدا گیریان خواردبێت.
کونکردنی گەڕان قۆناغی سەرەکییە کە گریمانەکان تێیدا تاقیدەکرێنەوە. بیرە پشیلەییەکان لە شوێنە بەڵێندەرەکاندا کون دەکرێن بۆ بەدەستهێنانی نمونەی ناوەکی و هەڵسەنگاندنی تایبەتمەندییەکانی خەزنەکان وەک کونیلەیی، ڕژانی و ڕێژەی شلە.
هەڵسەنگاندنی خەزانەکان دوای کونکردن دێت، کە ئەندازیاران داتاکان شی دەکەنەوە بۆ خەمڵاندنی قەبارە و کوالیتی و بەرهەمهێنانی خەزانەکە. تاقیکردنەوەی بەرهەمهێنان، ئەم هەڵسەنگاندنانە بە پێوانەکردنی ڕێژەی لێشاو و فشارەکان پشتڕاست دەکاتەوە.
خەمڵاندنی سەرچاوەکان داتای جیۆلۆجی و ئەندازیاری تێکەڵ دەکات بۆ ژماردن و دیاریکردنی قەبارەی هایدرۆکاربۆنە وەرگیراوەکان لە خەزنەی دۆزراودا. ئەم خەمڵاندنە بۆ هەڵسەنگاندنی توانای ئابووری دۆزینەوەکە گرینگە.
هەڵسەنگاندنی مەترسی لە گەڕانەکاندا بەهۆی نادڵنیایی جیۆلۆجی و هۆکارە ئابوورییەکانەوەیە. مۆدێلکردنی ئەگەری یارمەتیدەرە لە هەڵسەنگاندن و کەمکردنەوەی ئەم مەترسیانە، یارمەتی پرۆسەکانی بڕیاردان دەدات.
ڕەچاوکردنی ژینگەیی لە سەرووی هەموو چالاکییەکانی گەڕان و پشکنیندا لە پێش هەموو شتێکەوەیە. هەڵسەنگاندنی کاریگەری ژینگەیی دڵنیای دەدات لەوەی کە چالاکییەکانی گەڕان کەمترین زیان بە ئیکۆسیستەم، گیانلەبەرە کێوییەکان و کۆمەڵگاکان دەگەیەنن.
بە گشتی گەڕان بەدوای نەوتدا پرۆسەیەکی دینامیکی و چالاکە کە پێویستی بە تێکەڵەیەک لە شارەزایی زانستی و داهێنانی تەکنەلۆژی و بەڕێوەبردنی ژینگە هەیە بۆ دەستنیشانکردن و پەرەپێدانی سەرچاوە نوێیەکانی وزە
        `,
    hse: `هێمنایەتی HSE
لە کارەکانی پاڵاوگە و پترۆکیمیاییدا، پراکتیکەکانی تەندروستی، سەلامەتی و ژینگە (HSE) لە هەموو لایەنەکانی کارەکەدا چەسپاوە. ئەم ڕێبازە گشتگیرە پاراستنی کرێکاران و ژینگەی دەوروبەر و کۆمەڵگا بەگشتی مسۆگەر دەکات.
سەلامەتی کرێکاران لە ڕێگەی ڕاهێنانی بەرفراوان، پابەندبوونی توند بە پرۆتۆکۆڵەکانی سەلامەتی، و دابینکردنی ئامێری پاراستنی کەسی (PPE) لە پێشینەی کارەکاندایە. ڕێوشوێنەکان بۆ کەمکردنەوەی مەترسییەکانی پەیوەست بە مامەڵەکردن لەگەڵ ماددە کیمیاییەکان، پەستانی بەرز و پلەی گەرمی زۆر لە ئارادان.
سەلامەتی پرۆسەکان لە پێش هەموو شتێکەوەیە، بە ئامانجی ڕێگریکردن لە ڕووداوە کارەساتبارەکانی وەک ئاگرکەوتنەوە، تەقینەوە و دەردانی ماددە کیمیاییەکان. هەڵسەنگاندنی توندی مەترسی، شیکاری مەترسییەکانی پرۆسەکە، و جێبەجێکردنی سیستەمی سەلامەتی پێکهاتەی سەرەکی بەڕێوەبردنی سەلامەتی پرۆسەکانن.
پاراستنی ژینگە گرنگییەکی سەرەکییە، لەگەڵ پابەندبوونی توند بە ڕێساکانی بەڕێوەبردنی کوالیتی هەوا و ئاو، بەڕێوەبردنی پاشماوە و کۆنترۆڵکردنی پیسبوون. هەوڵەکان دەدرێت بۆ کەمکردنەوەی دەردانی گازی ژەهراوی، کەمکردنەوەی بەرهەمهێنانی پاشماوە، و پێشخستنی پراکتیکەکانی بەردەوامیی وەک کارایی وزە و دووبارە بەکارهێنانەوەی پاشماوە.
ئامادەکاری بۆ وەڵامدانەوەی فریاگوزاری زۆر گرنگە، لەگەڵ پلان و ڕاهێنانی گشتگیر بۆ چارەسەرکردنی ڕووداوەکانی وەک ڕژان، دزەکردن، ئاگرکەوتنەوە و کارەساتی سروشتی. ڕێوشوێنی وەڵامدانەوەی خێرا و کاریگەر یارمەتیدەرە بۆ کەمکردنەوەی کاریگەرییەکانی حاڵەتە فریاگوزاریەکان لەسەر تەندروستی مرۆڤ، ژینگە و کۆمەڵگاکانی دەوروبەر.
دەستپێشخەرییەکانی سەلامەتی ڕەفتار کلتوری سەلامەتی لە نێوان کارمەنداندا بەرەوپێش دەبەن، هاندەری ناسینەوەی مەترسی چالاکانە، ڕاپۆرتکردنی نزیک لە لەدەستدانەکان، و پابەندبوون بە شێوازە سەلامەتەکانی کارکردن. ئەمەش پابەندبوونێکی هاوبەش بە باشی HSE و باشترکردنی بەردەوام پەروەردە دەکات.
هەروەها سەلامەتی بەڵێندەرەکان لە پێشینەی کارەکانە، بەڵێندەران پێویستە پابەندبن بە هەمان ستاندارد و ڕێکارەکانی سەلامەتی وەک کارمەندانی کۆمپانیا. یەکدەنگی و لێپرسینەوە لە سەرانسەری هەموو چالاکییەکاندا دەپارێزرێت، بەبێ گوێدانە ئەوەی کێ ئەنجامی دەدات.
پابەندبوون بە ڕێساکان لە نزیکەوە چاودێری دەکرێت، لەگەڵ پیشەییەکانی HSE کە ئاگاداری گۆڕانکارییەکانی ڕێساکان و ستانداردەکانی پیشەسازی دەبن. وردبینی بەردەوام و ڕێوشوێنی چاکسازی دڵنیای دەدات لە پابەندبوونی بەردەوام و کەمترین مەترسییە یاسایی و داراییەکان.
پێشکەوتنی بەردەوام بەشێکی دانەبڕاوە لە هەوڵەکانی HSE، لەگەڵ وەبەرهێنان لە ڕاهێنان، توێژینەوە و داهێنان بە ئامانجی بەرزکردنەوەی ئەدای سەلامەتی، کەمکردنەوەی مەترسییەکان، و پەروەردەکردنی کلتوری بەرپرسیارێتی و باشی.
بە کورتی، HSE لە کارەکانی پاڵاوگە و پترۆکیمیاییدا ڕێبازێکی گشتگیرە کە سەلامەتی کرێکاران، سەلامەتی پرۆسێس، پاراستنی ژینگە، وەڵامدانەوەی فریاگوزاری، سەلامەتی ڕەفتار، سەلامەتی بەڵێندەر، پابەندبوون بە ڕێساکان و باشترکردنی بەردەوام لەخۆدەگرێت. ئەم پیشەسازیانە بە یەکەمایەتیدان بە HSE، هەوڵدەدەن بۆ دڵنیابوون لە خۆشگوزەرانی کرێکاران، پاراستنی ژینگە و کارکردن بە شێوەیەکی بەرپرسیارانە و بەردەوام
`,
    Instrument: `
لە پاڵاوگە و کارگەیەکی پترۆکیمیاییدا، ئامێرەکان ڕۆڵێکی گرنگ دەگێڕن لە چاودێریکردن و کۆنترۆڵکردنی لایەنە جیاوازەکانی پرۆسەکە بۆ دڵنیابوون لە کارکردنی سەلامەت و کارا. ئەم ئامێرانە بریتین لە گواستنەوەی پەستان، هەستەوەری پلەی گەرمی، پێوەرەکانی ئاست، پێوەرەکانی لێشاو، ئامێری شیکاری، ڤەڵڤەلی کۆنترۆڵ، سیستەمی ئامێری سەلامەتی (SIS)، سیستەمی چاودێریکردنی پەمپ و کۆمپرێسەر، سیستەمی کۆنترۆڵ، و ڤەڵڤەلی ڕزگارکردنی سەلامەتی. هەریەکێک لەم ئامێرانە خزمەت بە ئەرکێکی تایبەت دەکەن، وەک پێوانی پەستان، پلەی گەرمی، ڕێژەی لێشاو، یان پێکهاتەی کیمیایی، ڕێکخستنی ڕۆیشتنی شلە، دیاریکردنی بارودۆخی مەترسیدار، یان دڵنیابوون لە یەکپارچەیی ئامێرەکان. پێکەوە بەشداری دەکەن لە پاراستنی سەلامەتی پرۆسەکان، باشکردنی کارایی بەرهەمهێنان، و دڵنیابوون لە کوالیتی بەرهەم لە پیشەسازی پاڵاوگە و پترۆکیمیایی
`,
    Piping: `
چات جی پی تی
بۆری لە پیشەسازییەکانی پاڵاوگە و پترۆکیمیاییدا سیستەمی سووڕی خوێنی کارەکانە، کە بەرپرسیارە لە گواستنەوەی شلە و گازەکان لە سەرانسەری یەکە جیاوازەکانی پرۆسێسکردندا. ئەم تۆڕە ئاڵۆزانەی بۆرییە، لە بۆریی تیرەی بچووکەوە تا بۆرییەکانی تیرەی گەورە، ئاسانکاری دەکەن بۆ جوڵەی نەوتی خاو و بەرهەمە پاڵاوتەکان و ماددە کیمیاییەکان لە سەرتاسەری دامەزراوەکەدا.
لە پاڵاوگەکاندا، سیستەمی بۆری یەکەکانی پرۆسێسکردن وەک تاوەرەکانی تەقاندنەوە، کراکەری کاتالیتیک و هایدرۆتریتەری بەیەکەوە دەبەستێتەوە، ئەمەش ڕێگە بە گواستنەوەی ماددە سەرەکییەکان، ماددە ناوەندییەکان و بەرهەمە کۆتاییەکان دەدات. کارگە پترۆکیمیاییەکان بۆری بەکاردەهێنن بۆ گواستنەوەی ماددە خاوەکانی وەک ئیتیلێن و پرۆپیلین بۆ ڕیاکتۆرەکان کە لەوێدا گۆڕانکاری کیمیایییان بەسەردا دێت بۆ بەرهەمی بەنرخی وەک پلاستیک و توێنەر.
کەرەستەی بۆری دەبێت بەرگەی بارودۆخی سەخت بگرێت، لەوانە پەستانی بەرز، پلەی گەرمی و ژینگەی خراپ. ماددە باوەکان بریتین لە پۆڵا کاربۆنی، پۆڵا ستانلێس و ئالۆوی جۆراوجۆر کە بە پشتبەستن بە هۆکارەکانی وەک پێکهاتەی شلە و بارودۆخی کارکردن هەڵدەبژێردرێن.
ڕەچاوکردنی دیزاین بۆ پاڵاوگە و بۆری پترۆکیمیایی هۆکارەکانی وەک سەلامەتی، کارایی و چاککردنەوە لەخۆدەگرێت. ئەندازیاران دەبێت حساب بۆ فراوانبوونی گەرمی، شیکاری فشار، و پێکهاتەکانی پشتگیری بکەن بۆ دڵنیابوون لە متمانەپێکردن و یەکپارچەیی. سەرەڕای ئەوەش، پابەندبوون بە ستاندارد و ڕێساکانی پیشەسازی، وەک ASME B31.3 و API 570، زۆر گرنگە بۆ پابەندبوون و سەلامەتی.
پشکنین و چاککردنەوەی بەردەوام زۆر گرنگە بۆ ڕێگریکردن لە دزەکردن، ژەنگ و مەترسییە ئەگەرییەکانی تر. تەکنیکەکانی وەک تاقیکردنەوەی نا تێکدەر (NDT) و بەرنامەکانی بەڕێوەبردنی یەکپارچەیی یارمەتی دەستنیشانکردن و کەمکردنەوەی کێشەکان دەدەن پێش ئەوەی پەرە بسێنن.
بەگشتی، سیستەمی بۆری لە پاڵاوگە و کارگە پترۆکیمیاییەکان هێڵی ژیانن، کە ڕێگە بە کارکردنی نەرم و کارامەی پرۆسەی پیشەسازی ئاڵۆز دەدات لە هەمان کاتدا سەلامەتی و بەڕێوەبردنی ژینگەیی لە پێشینەدان
`,
    Production: `
    بەرهەمهێنانی نەوت بریتییە لە پرۆسەیەکی ئاڵۆز لە دەرهێنانی نەوتی خاو لە خەزنەکانی ژێر زەوی و پاڵاوتنی بۆ بەرهەمی جۆراوجۆری بەسوود. قۆناغی سەرەتایی بریتییە لە گەڕان بۆ دیاریکردنی شوێنی یەدەگی نەوتی ئەگەری بە بەکارهێنانی ڕووپێوی بومەلەرزەزانی و کونکردنی بیری گەڕان. کاتێک خەزنەیەکی بژاردەیی دۆزرایەوە، بیری بەرهەمهێنان کون دەکرێت بۆ دەرهێنانی نەوتی خاو.
لە کاتی بەرهەمهێناندا، ڕێگەی جۆراوجۆری وەک تەکنیکەکانی وەرگرتنەوەی سەرەتایی، لاوەکی و سێیەم بەکاردەهێنرێن بۆ زۆرترین دەرهێنانی ڕۆن. وەرگرتنەوەی سەرەتایی بریتییە لە پەستانی سروشتی خەزنەکە کە نەوتەکە پاڵدەنێت بۆ سەر ڕووی زەوی. پاشان شێوازەکانی وەرگرتنەوەی لاوەکی، وەک لێدانی ئاو یان غاز، جێبەجێ دەکرێن بۆ زیاتر بەرزکردنەوەی بەرهەمهێنان بە پاراستنی پەستانی خەزنەکە یان پاڵنانی نەوت. وەرگرتنەوەی سێیەم کە بە وەرگرتنەوەی ڕۆنی بەرزکراوە (EOR) ناسراوە، تەکنیکە پێشکەوتووەکانی وەک لێدانی کیمیایی یان پرۆسەی گەرمی بەکاردەهێنێت بۆ دەرهێنانی ئەو ڕۆنی ماوەتەوە.
کاتێک نەوتی خاو دەرهێنرا، پرۆسەی پاڵاوتنی لە پاڵاوگەکاندا تێدەپەڕێت. ئەمەش بریتییە لە جیاکردنەوەی نەوتی خاو بۆ هایدرۆکاربۆنە پێکهێنەرەکانی لە ڕێگەی پرۆسەکانی وەکو دیستڵەیشن، کرەکینگ و ڕیفۆرمینگ. بەرهەمە پاڵاوتراوەکان بریتین لە بەنزین، گازۆیل، سووتەمەنی جێت، چەورکەر و ماددە جۆراوجۆرەکانی پترۆکیمیایی.
بەرهەمهێنانی نەوت پێکهاتەیەکی گرنگی ئابووری جیهانییە، کە پێداویستییەکانی وزەی پیشەسازییەکان و گواستنەوە و ماڵەکان دابین دەکات. بەڵام لەگەڵ ئەوەشدا ئاستەنگی ژینگەیی وەک ڕژانی نەوت و دەردانی گازی گەرمخانەیی و لەناوچوونی شوێنی نیشتەجێبوون دروست دەکات. هەوڵەکان دەدرێن بۆ کەمکردنەوەی ئەم کاریگەرییانە لە ڕێگەی ڕێساکان و پێشکەوتنی تەکنەلۆژیا و گۆڕانکاری بەرەو سەرچاوەی وزەی نوێبووەوە.
    `,
    Software: `
    نەرمەواڵە لە پیشەسازی نەوتدا خزمەت بە چەندین ئەرکی گرنگ دەکات:  
گەڕان و بەرهەمهێنان: یارمەتیدەرە لە شیکاری داتاکانی بومەلەرزەزانی، مۆدێلکردنی خەزانەکان و باشکردنی ڕێژەی بەرهەمهێنان.
کارەکانی کونکردن: نەرمەواڵە یارمەتیدەرە لە ھاوشێوەکردنی کونکردن، چاودێریکردنی کاتی ڕاستەقینە و پلاندانان بۆ ڕێڕەوی بیرەکان.
بەڕێوەبردنی خەزان: یارمەتی پێشبینیکردنی بەرهەمهێنان و بەڕێوەبردنی پەستانی خەزنەکان و جێبەجێکردنی تەکنیکەکانی وەرگرتنەوەی نەوتی بەرزکراوە دەدات.
پاڵاوتن و پرۆسێسکردن: نەرمەواڵە پرۆسەکانی پاڵاوتنی ھاوشێوە دەکات، سەلامەتی مسۆگەر دەکات و پابەندبوون بە ڕێساکان دەپارێزێت.
بەڕێوەبردنی زنجیرەی دابینکردن: ئەمەش بریتییە لە نەرمەواڵە بۆ بەدواداچوونی کۆگا، باشکردنی لۆجستی و بەڕێوەبردنی ڕێگاکانی گواستنەوە.
شیکاری داتا و پشتگیری بڕیاردان: نەرمەواڵە داتا گەورەکان شی دەکاتەوە، پێشبینی شکستی ئامێرەکان دەکات و پشتگیری لە پرۆسەکانی بڕیاردان دەکات.
بە گشتی نەرمەواڵە زۆر گرنگە بۆ باشکردنی کارەکان و دڵنیابوون لە سەلامەتی و زۆرترین قازانج لە پیشەسازی نەوتدا
    `,
    "Pump & compressor": `
    لە پاڵاوگەکاندا، پەمپ و کۆمپرێسەرەکان ڕۆڵی گرنگ دەگێڕن لە پرۆسێسکردن و گواستنەوەی شلە و گازەکان بە درێژایی قۆناغە جیاوازەکانی کارەکانی پاڵاوتن.
پەمپەکان بۆ گواستنەوەی شلەمەنیەکانی وەک نەوتی خاو و بەرهەمە پاڵاوتەکان و ماددە جیاوازەکان لە نێوان یەکە جیاوازەکانی پرۆسێس لەناو پاڵاوگەکاندا بەکاردەهێنرێت. هەروەها بۆ سووڕانەوەی ئاوی ساردکەرەوە، گواستنەوەی سەرچاوەی سەرەکی بۆ ڕیاکتۆرەکان و گواستنەوەی بەرهەمەکان بۆ تانکی هەڵگرتن یان خاڵەکانی دابەشکردن بەکاردەهێنرێن. ئەم پەمپانە بە جۆری جیاواز دێن لەوانە پەمپە سەنتریفیووژەکان، پەمپەکانی جێگۆڕکێی ئەرێنی، و پەمپە فرە قۆناغییەکان، هەریەکەیان گونجاون بۆ بەکارهێنانی جیاواز لەسەر بنەمای هۆکارەکانی وەک ڕێژەی لێشاو، پەستان و چەسپاوی شلە.
لە بەرامبەردا کۆمپرێسەرەکان زۆر گرنگن بۆ مامەڵەکردن لەگەڵ گازەکانی ناو پاڵاوگە. پەستانی گازەکان زیاد دەکەن بۆ ئاسانکاری جووڵەیان لە ڕێگەی بۆری یان یەکەکانی پرۆسێسکردنەوە. بەکارهێنانی باوی کۆمپرێسەرەکان لە پاڵاوگەکاندا بریتین لە بەرزکردنەوەی پەستانی غازی سروشتی بۆ گواستنەوە، دابینکردنی هەوای پاڵەپەستۆکراو بۆ کارە جۆراوجۆرەکانی وەک ئامێری بای و سیستەمی هەوای ئامێر، و دابینکردنی گازە پەستان بەرزەکان کە پێویستن لە هەندێک پرۆسەی پاڵاوتنی وەکوو هایدرۆکراکینگ یان چاکسازی کاتالیتیک.
هەردوو پەمپ و کۆمپرێسەر پێویستیان بە هەڵبژاردن و دانان و چاککردنەوەی ورد هەیە بۆ دڵنیابوون لە کارایی و متمانەپێکراوی گونجاو. هۆکارەکانی وەک بارودۆخی کارکردن، تایبەتمەندی شلە و ڕەچاوکردنی سەلامەتی دەبێت لە کاتی دیزاینکردن و کارکردنیاندا لەبەرچاو بگیرێن. چاککردنەوە و چاودێریکردنی بەردەوام زۆر گرنگە بۆ ڕێگریکردن لە وەستان و دڵنیابوون لە سەلامەتی و درێژکردنەوەی تەمەنی خزمەتگوزاری ئەم سەروەت و سامانە گرنگانە.
بە کورتی، پەمپ و کۆمپرێسەرەکان پێکهاتەی پێویستن لە کارەکانی پاڵاوگەدا، کە جووڵەی شلە و گازە پێویستەکان بۆ پرۆسە و کارە جیاوازەکانی پاڵاوتن ئاسان دەکەن. هەڵبژاردن و دانان و چاککردنەوەی دروستیان زۆر گرنگە بۆ دڵنیابوون لە کارەکانی پاڵاوگەی کارا و متمانەپێکراو.
    `,
    Reservoir: `ئەندازیاری خەزنەکان لە نەوتدا ئەو دیسیپلینە تایبەتمەندەیە کە گرنگی بە تێگەیشتن و باشکردنی بەرهەمهێنانی هایدرۆکاربۆنەکان لە خەزنەکانی ژێر زەوی دەدات. بریتییە لە جێبەجێکردنی بنەماکانی فیزیا و جیۆلۆجی و ئەندازیاری بۆ تایبەتمەندکردنی کۆگاکان و پێشبینیکردنی هەڵسوکەوتەکانیان و داڕشتنی ستراتیژییەکان بۆ زۆرترین چاکبوونەوە. ئەندازیارانی خەزانەکان لە تەکنیکە جیاوازەکانی وەک تاقیکردنەوەی بیر، ھاوشێوەکردنی ژمارەیی و مۆدێلکردنی خەزان کەڵک وەردەگرن بۆ هەڵسەنگاندنی هۆکارەکانی وەک لێشاوی شلە، پەستان و تایبەتمەندی بەردەکان. ئامانجی کۆتایی ئەوان دڵنیابوونە لە دەرهێنانی نەوت و گاز بە شێوەیەکی کارا و بەردەوام لە هەمان کاتدا کەمترین تێچوون و کاریگەری لەسەر ژینگە. ئەوان ڕۆڵێکی گرنگ دەگێڕن لە دیاریکردنی توانای پڕۆژەکانی نەوت و گاز، باشکردنی ڕێژەی بەرهەمهێنان، و درێژکردنەوەی تەمەنی خەزانەکان لە ڕێگەی تەکنیکەکانی وەک بەرزکردنەوەی وەرگرتنەوەی نەوت (EOR).`,
    "tank & storage": `تانکی و دامەزراوەی هەڵگرتن پێکهاتەیەکی گرنگی ژێرخانی پاڵاوگەکانن، وەک بڕبڕەی پشت بۆ مامەڵەکردن لەگەڵ نەوتی خاو و بەرهەمە مامناوەندەکان و بەرهەمە نەوتییە تەواوەکان کاردەکەن. ئەم دامەزراوانە لە جۆرەها تانکی پێکدێن، لەوانە تانکی هەڵگرتنی نەوتی خاو، تانکی هەڵگرتنی مامناوەند، و تانکی هەڵگرتنی بەرهەم. تانکی هەڵگرتنی نەوتی خاو بە شێوەیەکی گشتی دەفری گەورە و پۆڵاین کە بەکاردەهێنرێن بۆ هەڵگرتنی نەوتی خاوی هاتوو پێش پرۆسێسکردن. ئەوان بە شێوەیەک دروستکراون کە مامەڵە لەگەڵ قەبارەی زۆری نەوتی خاودا بکەن کە لە کێڵگەکانی بەرهەمهێنانەوە وەرگیراون و لە بارودۆخێکی گونجاودا بیهێڵنەوە تاوەکوو ئامادە دەبێت بۆ پاڵاوتن.
تانکی هەڵگرتنی مامناوەند بەکاردەهێنرێت بۆ هەڵگرتنی کاتی بەرهەمە مامناوەندەکان کە لە کاتی پرۆسەی پاڵاوتندا دروست دەبن، وەک بەنزین، گازۆیل، سووتەمەنی جێت و سەرچاوەی پترۆکیمیایی جۆراوجۆر. ئەم تانکانە ڕێگە بە جیاکردنەوە و دابەشکردنی کۆنترۆڵکراوی بەرهەمە جیاوازەکان دەدەن لەناو پاڵاوگەکەدا، ئەمەش ئاسانکاری دەکات بۆ بەرهەمهێنانی کارا و کارەکانی لۆجستی.
تانکی هەڵگرتنی بەرهەمەکان شوێنی کۆتایین بۆ بەرهەمە نەوتییە پاڵاوتەکان پێش ئەوەی دابەش بکرێن بەسەر بەکارهێنەرانی کۆتایی یان بگوازرێنەوە بۆ تێرمیناڵەکان بۆ دابەشکردنی زیاتر. ئەم تانکیانە بۆ هەڵگرتنی بەرهەمە تەواوەکانی وەک بەنزین و گازۆیل و نەوتی سپی و ڕۆنی گەرمکەرەوە بە ڕێژەیەکی زۆر دروستکراون. تایبەتمەندی سەلامەتییان تێدایە بۆ ڕێگریکردن لە پیسبوونی بەرهەم و دزەکردن و مەترسییەکانی ژینگە.
جگە لە تانکی هەڵگرتن، پاڵاوگەکان دامەزراوەی هەڵگرتنی دیکەی وەک گۆیی بۆ هەڵگرتنی گازە شلەکان،  (LPG) و بەرهەمە ناجێگیرەکانی تریش بەکاردەهێنن. ئەم دامەزراوە هەڵگرتنانە زۆر گرنگن بۆ پاراستنی دابینکردنی بەردەوامی بەرهەمە نەوتییەکان بۆ دابینکردنی خواستی بازاڕ و وەک یەدەگی ستراتیژی لە ماوەی پچڕانی دابینکردن یان حاڵەتە فریاگوزارییەکاندا کاردەکەن.
بەگشتی، تانکی و دامەزراوەکانی هەڵگرتن لە پاڵاوگەکاندا ڕۆڵێکی گرنگ دەگێڕن لە دڵنیابوون لە کارپێکردنی کارا و سەلامەتی و متمانەپێکراوی پرۆسەی پاڵاوتن، ئەمەش ڕێگە خۆش دەکات بۆ هەڵگرتن و دابەشکردنی بەرهەمە نەوتییەکان بۆ بازاڕە جیهانییەکان.`,
    Laboratory: `تاقیگەی پاڵاوگەکان دامەزراوەی ئاڵۆزن کە پێویستن بۆ کۆنترۆڵکردنی کوالیتی، باشکردنی پرۆسەکان و لێکۆڵینەوە. ئەوان ئامێری پێشکەوتوو و کەسانی لێهاتوو بەکاردەهێنن بۆ شیکردنەوەی نەوتی خاو و بەرهەمە مامناوەندەکان و کاڵای نەوتی تەواو. ئەم تاقیگانە پابەندبوون بە ستانداردەکانی پیشەسازی مسۆگەر دەکەن، پرۆسەکانی پاڵاوتن باشتر دەکەن، تەکنەلۆژیای نوێ پەرەپێدەدەن و چاودێری کاریگەرییەکانی ژینگە دەکەن. ئەم تاقیگانە بە ئامێرەکانی وەک ڤیسکۆزیتەمەتر، دانستیەمەتر و سولفورمەتر و... تەیارکراون، داتا و تێڕوانینێکی گرنگ پێشکەش دەکەن بۆ پشتگیریکردن لە بڕیاردان لە سەرانسەری پاڵاوگەکەدا، بەرزکردنەوەی کوالیتی بەرهەم، کارایی و بەڕێوەبردنی ژینگە.`,
  };
  
  return (
    <div className="book-box" id="booklistpage">
        {/* <FiMenu className="main-page-menu" onClick={()=>hidehefer()}/> */}
      <div className="boox-box-top">
        
          <img className="book-box-right" src={`/booklist/${name}.jpg`} alt={name} />
          <div className="book-box-left">
            <p>{dataBookList[name]}</p>
          
        </div>
      </div>
      <div className="book-box-buttom">
        {loading?<p>loading</p>:
        books.map((book)=>(
            <BookCard book={book}/>
        ))}
      </div>
    
      
    </div>
  );
}

export default BookListPage;
