import { useState } from "react";
import React from 'react';
import "./AdminPanel.css"
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';


function AdminPanel(){
    
    const [name , setName] = useState('')
    const [year,setYear ] = useState()
    const [about ,setAbout ] = useState('')
    const [abouts ,setAbouts ] = useState([])
    const [info ,setInfo ] = useState('')
    const [image ,setImage ] = useState('')
    const [file ,setFile ] = useState()
    const [author ,setAuthor ] = useState()
    const [messageBook,setMessageBook] = useState(false)

    function addabouts(){
        setAbouts(prev=>[...prev,about])
        setAbout('')
    }

    function removeabout(indextoremove){
        const filteredAbouts = abouts.filter((_, index) => index !== indextoremove);
        setAbouts(filteredAbouts)
    }

    async function addBook(){
        const newBook = [{'namebook':name ,'yearbook':year , 'aboutbook':abouts , 'infobook':info , 'authorbook':author , 'imagebook':image , 'filebook':file}]
        const response = await axios.post('https://petromk.com/api/admin/newbook' , newBook)
        .then(response=>{setMessageBook(true)})
        .catch(error=>{console.log('error ' , error.message)})
        setTimeout(()=>{
            setMessageBook(false)
        },5000)
    }

    function coverToBase64(e){
        
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload=()=>{
            console.log(reader.result)
            setImage(reader.result)
        };
        reader.onerror=error=>{
            console.log("Error", error)
        }
    }
    function coverToBase64pdf(e){
        
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload=()=>{
            console.log(reader.result)
            setFile(reader.result)
        };
        reader.onerror=error=>{
            console.log("Error", error)
        }
    }

    return(
        <>
            <div className='adminopanel'>
                {messageBook && 
                <Alert key='success' variant='success'>
                    کتێب به سەرکەوتوویی خرایه سەر سایت
                </Alert>
                }
            
                <div className='adminopanel-book'>
                    <h3>زیادکردنی کتێب</h3>
                    <input onChange={(e)=>setName(e.target.value)} name="namebook" type="text" placeholder='ناوی کتێب'/>
                    <input onChange={(e)=>setYear(e.target.value)} name="yearbook" type="text" placeholder='ساڵی له چاپدان'/>
                    {/* <input onChange={(e)=>setAbout(e.target.value)} name="aboutbook" type="text" placeholder='سەبارەت به'/> */}
                    <textarea onChange={(e)=>setInfo(e.target.value)} name="infobook" type="text" placeholder='زانیاری زیاتر'/>
                    <div className="adminpanel-book-about">
                        <input value={about} list="aboutbook" name="aboutbook" placeholder='بابەت' onChange={(e)=>setAbout(e.target.value)}/>
                            <datalist id="aboutbook" >
                                <option value="Instrument" />
                                <option value="Laboratory" />
                                <option value="English" />
                                <option value="Production" />
                                <option value="Software" />
                                <option value="tank & storage" />
                                <option value="Electrical" />
                                <option value="Piping" />
                                <option value="hse" />
                                <option value="Exploration" />
                                <option value="Pump & compressor" />
                                <option value="Reservoir" />
                                <option value="Drilling" />
                                <option value="Process" />
                            </datalist>
                            <button onClick={addabouts}>زیادکردنی سەبارەت به</button>
                            <ul>
                        {abouts.map((abou,index)=>(
                            <li key={index} onClick={()=>removeabout(index)}>{abou}</li>
                        ))}
                    </ul>
                    </div>
                    

                    <input onChange={(e)=>setAuthor(e.target.value)} name="authorbook" type="text" placeholder="ناوی نووسەر"/>
                    <input onChange={(e)=>coverToBase64(e)} name="imagebook" type="file" />
                    <input onChange={(e)=>coverToBase64pdf(e)} name="filebook" type="file" />
                    <button onClick={()=>addBook()}>زیادکردنی کتێب </button>
                </div>
            </div>
        </>
    )
}

export default AdminPanel;