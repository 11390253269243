import "./BookList.css";
import { Link } from "react-router-dom";



function BookList() {
  const books = [
    {
      img: "Instrument.jpg",
      name: "Instrument",
      about:"ئینستروومێنت ئەو ئامێرە کارەباییانەیە وەکوو ڤەڵڤ و گەیەنەری پلەی گەرمی و گەیجی پەستان و.... کە کۆنتڕۆڵی پلەی گەرمی ،ئاست ،پەستان و لێشاومان بۆ ئاسان دەکاتەوە"
    },
    {
      img: "Laboratory.jpg",
      name: "Laboratory",
      about:"تاقیگە ئەو بەشە کە لە هەموو شوێنە نەوتییەکان هەیە، هەتا بتوانن جۆری ئەو خۆراکەی وەری دەگرن و هەروەها جۆری بەرهەمەکانیشیان دیاری بکەن و ئەگەر پێویست بوو گۆڕانکاریگەلێک لە ناو پرۆسسدا بکەن"
    },
    {
      img: "English.jpg",
      name: "English",
      about:"چونکە کۆمپانیا نەوتییەکان لە هەموو نەتەوەکانی تێدایە زمانی ئینگلیزی بووەتە زمانێکی هاوبەشی ئەندازیارانی ئەو بەشە، ئەگەر شارەزایی ئاخاوتنتان نەبێ وەرگیرانتان لە شوێنە گەورە و نێودەوڵەتییەکان دوورە"
    },
    {
      img: "Production.jpg",
      name: "Production",
      about:"ڕێک هەر لەدوای کونکردن ئەم بەشە دەست پێدەکەن و کارەکانی بیرەکە تەواو دەکەن و دەست دەکەن بە بەرهەمهێنان و پاڵاوتن و گۆڕینی ئەو نەوتە خامە بۆ بەرهەمەلێکی بەسوود کە خەڵكی بەکاری دێنن"
    },
    {
      img: "Software.jpg",
      name: "Software",
      about:"هەر بەشێکی ئەندازیاری نەوت خاوەن نەرمەواڵەی تایبەتی خۆیانن کە ئیشەکانیان پێ جێبەجێ دەکەن، ئێمە لێرە هەوڵمان داوە بەناوبانگترینەکانیان دانێین و شێوازی بەکارهێنانیشی باس بکەین بۆتان"
    },
    {
      img: "tank & storage.jpg",
      name: "tank & storage",
      about:"چونکە ناتوانین بۆ هەر مادەیەک و بۆ هەموو شوێنێک بۆری ڕاکێشین تانک و خەزان پێداویستییەکی سەرەکین و بەگوێرەی مادەکەی ناوی، شیواز و قەبارە و جۆری تانکەکان دەگۆڕدرێت"
    },
    {
      img: "hse.jpg",
      name: "hse",
      about:"هێمنایەتی تەنیا ئەرکی ئچ ئس ئی نییە بەڵکوو کولتوورێکە کە ڕچاو کردنی بۆ هەموو ئەندامان پێویستە،  بەشێکی گرنگی دەبێتە بەڕێوبەرایەتی هێمنایەتی پرۆسس کە زانستێکی تازەیە لێرە جوان باسمان کردووە"
    },
    
    {
      img: "Piping.jpg",
      name: "Piping",
      about:"ڕاکێشانی بۆری و ڕێکخستنیان بە ڕێکوپێکی بە هەندێ ئستاندارد و نەرمەواڵە جێبەجێ دەکرێت کە بەشێکی کاتگر و گرنگە لە دەمی دروست کردنی کارگەکاندا"
    },
    {
      img: "Electrical.jpg",
      name: "Electrical",
      about:"دابین کردنی کارەبا لە کوردستان زۆرتر بە ژنراتۆرە هەتا کارەبای شاری، هەر لە بەرهەمهێنانی کارەباوە هەتا دابەش کردنی بە ناو سایتدا و بەکارهێنانی لە ئامێرەکاندا هەمووی لەسەر ئەم بەشەیە"
    },
    {
      img: "Exploration.jpg",
      name: "Exploration",
      about:"دۆزینەوە و پەیداکردنی نەوت و گاز پرۆسەیەکی کاتگرە کە ئەگەری سەرکەوتنیشی لە سەدا 35 کەمتریشە، بەڵام لە هەندێ وڵاتدا جارێ وازیان لە دۆزینەوە هێناوە چونکە ئەوەی هەیانە بۆیان نافرۆشرێ"
    },
    {
      img: "Pump & compressor.jpg",
      name: "Pump & compressor",
      about:"بە بێ پومپ و کۆمپرێسۆر کارگەکان ناتوانن درێژە بەکارکردنیان بدەن و بەهۆی ئەوەی کە بەردەوام خەریکی کارن زوو زوو خراپ دەبن و دەبێت چاک بکرێنەوە"
    },
    {
      img: "Reservoir.jpg",
      name: "Reservoir",
      about:"ئەندازیاری خەزانەکان هەر لە دۆزینەوە هەتا کۆتایی هێنانی بیرەکە دەبێت لەگەڵ بەشەکانی تر وەکوو دۆزینەوە، هەڵکۆڵین، بەرهەمێنان هاوئاهەنگییان ببێت تا باشتر بتوانن خەزانەکانی بن زەوی بەتاڵ کەن"
    },
    {
      img: "Drilling.jpg",
      name: "Drilling",
      about:"هەڵکۆڵین و کونکردن یەکێک لە بەرچاوترین بەشەکانی ئەندازیاری نەوتە کە بەردەوام خەریکی بەڕۆژبوونەوەیە هەتا مەترسییەکان و خەرجەکانی کەمتر بێتەوە"
    },
    {
      img: "Process.jpg",
      name: "Process",
      about:
        "کیمیا و پرۆسس وەکوو دڵی کارگەکان وایە، کە لەم بەشەدا تەواوی کارلێکە کیمیاییەکان لە ناو تاوەڕ و ڕیاکتەرەکان ڕوو دەدەن وەکوو شکاندنی پێکهاتە هایدرۆکاربۆنییە درێژەکان و...",
    },
  ];

  return (
    <>
      <div className="booklist">
        <div className="booklist-page">
          {books.map((book) => (
            <div className="booklist-card">
              <img src={`/booklist/${book.img}`} alt={book.name} />
              <div>
                <h2>{book.name}</h2>
                <p>{book.about}</p>
                <button><Link to={`${book.name}?`} >  بینینی کتێبەکانی ئەم بابەتە </Link></button>
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default BookList;
