import Main from "./Component/Main/Main";
import Courses from './Component/Courses/Courses';
import Books from './Component/Books/Books';
import Book from './Component/Books/Book';
import About from './Component/About/About';
import Users from './Component/Users/Users';
import Login from "./Component/Login/Login";
import CourseDrilling from "./Component/Courses/CourseDrilling/CourseDrilling";
import E404 from "./Component/404/404";
import AdminPanel from "./Component/AdminPanel/AdminPanel";
import BookPage from "./Component/Books/BookPage";
import BookList from "./Component/BookList/BookList";
import BookListPage from "./Component/BookList/BookListPage/BookListPage";
import GetDataSiteMao from "./GetData/GetDataSiteMap";
import path from "path-browserify";

const routes=[
    {path:"/" , element:<Main />},
    {path:"/courses" , element:<Courses />},
    {path:"/courses/drilling" , element:<CourseDrilling />},
    {path:"/books" , element:<Books />},
    // {path:"/books/:namebook" , element:<Book />},
    {path:"/books/:namebook" , element:<BookPage />},
    {path:"/book" , element:<BookList />},
    {path:"/book/:name" , element:<BookListPage />},
    {path:"/book/:about/:name" , element:<BookPage />},
    {path:"/about" , element:<About />},
    {path:"/users" , element:<Users />},
    {path:"/login" , element:<Login />},
    {path:"/adminpanel", element:<AdminPanel />},
    {path:'/getdatasitemap' , element:<GetDataSiteMao />},
    {path:"*" , element:<E404 />},
    
]


export default routes;