import React, { useEffect, useState } from 'react';
import './Book.css'
import { useLocation } from 'react-router-dom';
import axios from "axios";
import base64js from 'base64-js';
import { useParams } from 'react-router-dom';

function BookPage (){
    const [pdfBook,setPdfBook] = useState()
    const [book,setBook] = useState()
    const [loading , setLoading] = useState(true)
    const location = useLocation();
    const { about, name } = useParams();


    useEffect(()=>{
        const queryParams = new URLSearchParams(location.search);
        
        const fetchData = async ()=>{
                if(!JSON.parse(queryParams.get('data'))){
                    try{
                        const response = await axios.get(`https://petromk.com/api/admin/books/${name}`);
                        setBook(response.data[0]);
                        setLoading(false)
                        
                    }catch(error){
                        console.log(error)
                    }                     
                }else{
                    
                    setBook(JSON.parse(queryParams.get('data'))) 
                    setLoading(false)
                }
        } 
        fetchData();
    },[])
    
    // async function getData(){
    //    if(!book){
    //     await axios.get(`https://petromk.com/api/admin${location.pathname}`)
    //     .then((res)=>setBook(res.data[0]))
    //     .catch((error)=>console.log(error))
    //     } 
    //     console.log(book)
    // }
    // getData()
    
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function downloadPdf(){
       const response=await axios.get(`https://petromk.com/api/admin/books/pdf${book.namebook}`)
        await setPdfBook(response.data[0].filebook)
if (!pdfBook) {
    console.error('PDF data is undefined or null.');
    return;
  }

  // Convert base64 to binary
  const binaryData = base64js.toByteArray(pdfBook);

  // Create a Blob from the binary data
  const blob = new Blob([binaryData], { type: 'application/pdf' });

  // Create a data URL
  const url = URL.createObjectURL(blob);

  // Create an anchor element
  const a = document.createElement('a');
  a.href = url;
  a.download = 'downloaded.pdf';

  // Append the anchor to the document and trigger a click event
  document.body.appendChild(a);
  a.click();

  // Remove the anchor from the document
  document.body.removeChild(a);

  // Revoke the URL to free up resources
  URL.revokeObjectURL(url);
          
    }
    
    function hidehefer(){
        document.getElementById('books').classList.toggle('show-header')
      }
    return(
        <>
        <div className='book-page'>
            {loading ? <p>loading</p> :(<><div className='book-page-top'>
                <div className='book-page-title'>
                    <img src={book.imagebook} alt={book.title} />
                </div>
                <div className='book-page-inf'>
                {book.namebook?(<h1>ناوی کتێب: {book.namebook}</h1>):<></>}
                    {book.authorbook?(<h3>نووسەر:  {book.authorbook}</h3>):<></>}
                    {book.yearbook?(<h3>سالی لەچاپدان:  {book.yearbook}</h3>):<></>}
                    <h3>سەبارت به :  </h3>
                    <ul className='book-page-inf-about'>
                        {book.aboutbook.map((about)=>(
                        <li >{about}</li>
                    ))}
                    </ul>
                    <p>زانیاری :  {book.infobook}</p>
                </div>
            </div>
            <div className='book-page-buttom'>
                    
                    <button className='book-page-buttom-button' onClick={downloadPdf}>داگرتنی کتێب</button>
            </div>
        </>)}
            
            
        </div>
        

        </>
    )
}
export default BookPage