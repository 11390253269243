import React, { useState } from "react";
import "./Login.css";
import { Button } from "react-bootstrap";
import {FiMenu} from 'react-icons/fi'
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import { FaCircleCheck } from "react-icons/fa6";


export default function Login() {
    const [login , setLogin] = useState(true)
    const [name ,setName ] = useState('')
    const [email ,setEmail ] = useState('')
    const [password ,setPassword ] = useState('')
    const [alert,setAlert] = useState(false)
    const [statusCode,setStatusCode] = useState()
    const [resData,setResData] = useState()
    const [auth,setAuth] = useState()

    function hidehefer(){
      document.getElementById('login').classList.toggle('show-header')
    }
    async function adduser(){
      if(name===''|| email==='' || password===''){
        setAlert(true)
      }else{
        setAlert(false)
        const newuser = [{"nameuser":name , "emailuser":email , "passworduser":password}]
      const response =await axios.post('https://petromk.com/api/admin/users',newuser)
      .then(response=>{setResData(response.data)
      setAlert(response.data)
      setStatusCode(response.status)})
      .catch(error=>{console.log('error ' , error.message)})
      
      }
    }

    async function loginFeatch(){
      if(email==='' || password===''){
        setAlert(true)
      }else{
        setAlert(false)
        const user = [{"emailuser":email,"passworduser":password}]
        const response = await axios.post('https://petromk.com/api/admin/loginusers',user)
        .then(res=>console.log(res))

        const accessToken = response?.data?.accessToken;
        const roles = response?.data?.roles;
        setAuth({email,roles,accessToken})
        
      }

      

    }
    async function Testt(){
        const response = await axios.get('https://petromk.com/api')
        .then(res=>console.log(res))
      }
      Testt()

  return (
    <div className="login" id="login">
      
      <FiMenu className="main-page-menu" onClick={()=>hidehefer()}/>
      <div className="login-box">
        {statusCode===200?(
          <>
          <div style={{textAlign:"center"}}>
          <FaCircleCheck style={{fontSize:"10rem",color:"green",margin:"2rem"}}/>
          <h5>هەژمار به سەکەوتویی دروست کرا</h5>
          <button className="buttonlogin">چوونه ژوورەوە</button>
          </div>
          
          </>
          
        ):(<div className="login-box-right">
        {alert?(<Alert style={{backgroundColor:"red",border:"none"}} key="danger" variant="danger">
          {resData}
        </Alert>):(<p></p>)}
        
            {login ? <>
            <input onChange={(e)=>setName(e.target.value)} name="name" type="text" placeholder="ناو" />
            <input onChange={(e)=>setEmail(e.target.value)} name="email" type="email" placeholder="ئیمەیل" id="showInput" />
            <input onChange={(e)=>setPassword(e.target.value)} name="password" type="password" placeholder="نهێن وشه" />
            <Button onClick={adduser} id="showButton">ناو تۆمار کردن</Button>
            <div>
            <p> هەژمارت هەیه ؟ </p>
            <a href="#"
              onClick={() => {
                setLogin(false)
              }}
            >
              چوونه ناو هەژمار
            </a>
          </div></> : 
          <>
          <input onChange={(e)=>setEmail(e.target.value)} name="email" type="email" placeholder="ئیمەیل" />
          <input onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="نهێن وشه" />
          <Button id="showButton" onClick={loginFeatch}>چوونه ژوورەوە</Button>
          <div>
            <p> هەژمارت نییه ؟ </p>
            <a
              href="#"
              onClick={() => {
                setLogin(true)
              }}
            >
              درووستکردنی هەژمار
            </a>
          </div></>}
          
        </div>)}
        
        <div className="login-left">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
            <defs>
              <clipPath id="clip2">
                <path d="M19 327h11v1H19zm0 0"></path>
              </clipPath>
              <clipPath id="clip3">
                <path d="M29.125 327.566c.008-.113.016-.242.02-.378h.34s-.087.164-.36.378m-8.95-.074c-.183-.18-.238-.305-.238-.305h.223c.004.106.008.211.016.305"></path>
              </clipPath>
              <clipPath id="clip4">
                <path d="M42 389h13v16.02H42zm0 0"></path>
              </clipPath>
              <clipPath id="clip5">
                <path d="M30 389h13v16.02H30zm0 0"></path>
              </clipPath>
              <clipPath id="clip6">
                <path d="M42 389h2v16.02h-2zm0 0"></path>
              </clipPath>
            </defs>
            <g>
              <path
                fill="#3D91A6"
                d="M28.129 335.797l-3.453 2.703-4.153-2.633 1.391-.258v-1.87h5.258v1.835l.957.223"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M27.172 334.227h-5.258a.366.366 0 01-.363-.368v-.004c0-.203.164-.367.363-.367h5.258c.2 0 .363.164.363.367v.004a.366.366 0 01-.363.368"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M34.93 338.32c-.157-1.523-7.055-2.629-7.055-2.629l-3.219 2.23-3.433-2.23s-6.766 1.133-6.864 2.63c-1.382 7.792-1.62 27.874-1.62 27.874l3.452-.03-.007-21.274.007 24.71h16.79l.007-3.437h3.426s-.043-21.195-1.484-27.844"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M31.277 344.89v-8.585l-2.058-.438v6.375h-9.246v-6.375l-2.055.438v8.586h-1.734l.007 24.71 2.45 30.212h11.773l2.566-30.211V344.89h-1.703"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M15.422 367.969s.433-.125.601-.688c.25-1.215.16-1.77.16-1.77l.036-6.968h-4.032l.333 6.836c-.207 1.375-1.38 2.703-1.286 3.492.54.887 1.41 2.621 2.262 2.703.918-.316 2.152-.887 2.723-2.058-.117-.743-.797-1.547-.797-1.547M33.777 367.969s-.433-.125-.601-.688c-.25-1.215-.16-1.77-.16-1.77l-.036-6.968h4.032l-.332 6.836c.207 1.375 1.379 2.703 1.285 3.492-.54.887-1.41 2.621-2.262 2.703-.918-.316-2.152-.887-2.723-2.058.118-.743.797-1.547.797-1.547"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M36.48 365.375c0 .07-.449.125-1.003.125-.555 0-1.008-.055-1.008-.125s.453-.125 1.008-.125c.554 0 1.003.055 1.003.125M14.75 365.375c0 .07-.453.125-1.008.125-.555 0-1.004-.055-1.004-.125s.45-.125 1.004-.125c.555 0 1.008.055 1.008.125"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#1A1D2E"
                d="M24.945 385.836c0-8.477-.16-15.352-.363-15.352-.2 0-.363 6.875-.363 15.352 0 8.476.164 13.976.363 13.976.203 0 .363-5.5.363-13.976"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M32.133 388.398s-1.477 10.41-.938 10.594c.91.309 2.387.86 3.387 1.235.645.242 1.07.859 1.07 1.546v.602H24.527v-14.238l7.606.261M16.926 388.398s1.476 10.41.937 10.594c-.91.309-2.386.86-3.386 1.235-.649.242-1.07.859-1.07 1.546v.602h11.12v-14.238l-7.601.261"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M24.816 402.375h-.468v-14.238h.468v14.238"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#1A1D2E"
                d="M29.133 351.297h-9.117v-2.313h9.117v2.313"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M28.469 354.648h-7.98v-3.351h7.98v3.351"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#582A1A"
                d="M20.445 327.965c-.64 1.207.79 5.402 1.778 5.402h4.761c.98 0 2.696-4.488 1.817-5.375-1.09-.742-3.824-1.672-3.824-1.672s-3.711.934-4.532 1.645"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FFB7A4"
                d="M20.96 330.402c-.562.114-1.124-.304-1.257-.933-.133-.633.219-1.239.781-1.356.563-.117 1.04 2.168.477 2.29M28.215 330.402c.562.114 1.129-.304 1.258-.933.132-.633-.22-1.239-.782-1.356-.566-.117-1.039 2.168-.476 2.29"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FFB7A4"
                d="M24.617 334.586c4.672 0 3.922-8.016 3.922-8.016s-.191-2.629-3.82-2.629c-3.168 0-3.946 2.692-3.946 2.692s-.863 7.953 3.844 7.953"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#4F2E1E"
                d="M26.469 332.234h-.336s-.313 1.188-.41 1.313c-.157.2-.407.402-.563.351-.156-.05-.254-.34-.328-.378l-.172-.086v-.004.004-.004.004l-.168.086c-.074.039-.176.328-.332.378-.152.051-.406-.152-.558-.351-.098-.125-.415-1.313-.415-1.313h-.335s-.094 2.02.902 2.414c.078-.28.18-.105.18-.105s.25.523.722.535h.012c.473-.012.719-.535.719-.535s.105-.176.18.105c1-.394.902-2.414.902-2.414"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#5E3624"
                d="M24.64 331.594c.028.46-.706.523-1.273.597-.523.07-.672.465-.672.465s-.375-.86.09-1.144c.582-.36 1.82-.41 1.856.082"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#5E3624"
                d="M24.656 331.594c-.031.46.703.523 1.27.597.523.07.676.465.676.465s.37-.86-.094-1.144c-.578-.36-1.82-.41-1.852.082"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#582A1A"
                d="M20.266 328.188c.382-.086.789.644.789.644l.16-2.781s.156-1.371 1.648-.875c.555 1.144 3.137 1.09 3.614-.012 1.242-.492 1.484.879 1.484.879l.32 2.922c.2-.781.754-.703.754-.703s1.192-5.575-4.387-5.575c-5.582 0-4.39 5.344-4.382 5.5"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <g
                clipPath="url(#clip2)"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              >
                <g clipPath="url(#clip3)">
                  <path
                    fill="#D9D9D9"
                    d="M19.938 327.566h9.546v-.378h-9.547zm0 0"
                  ></path>
                </g>
              </g>
              <path
                fill="#D99C8C"
                d="M24.73 328.625c-1.78 0-2.918-.262-3.628-.563l.046-.875h6.938c.035.301.07.622.102.907-.723.289-1.817.531-3.458.531"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#4B2416"
                d="M28.188 328.094a129.42 129.42 0 00-.102-.906h1.059a9.374 9.374 0 01-.02.378c-.2.164-.5.356-.938.528m-7.085-.031c-.465-.196-.754-.407-.926-.57-.008-.095-.012-.2-.016-.305h.988l-.046.875"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FF892E"
                d="M24.82 321.121h-.449a4.898 4.898 0 00-4.894 4.899v.05h10.242v-.05a4.899 4.899 0 00-4.899-4.899"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M29.477 325.582c-3.254-.8-2.82-2.852-4.051-4.285h-1.723c-1.23 1.433-.793 3.484-4.047 4.285h9.82"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FF892E"
                d="M25.41 320.773h-1.656s-.09 4.832-4.629 4.832v.754h10.914v-.754c-4.539 0-4.629-4.832-4.629-4.832"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M30.04 325.996H19.124v.844h10.914v-.844"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FF892E"
                d="M19.125 326.84s2.762-.414 5.473-.422c2.746-.004 5.441.422 5.441.422 0 .531-2.719 1.223-5.441 1.223-2.735 0-5.473-.696-5.473-1.223"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M64.441 335.797l-3.453 2.703-4.152-2.633 1.394-.258v-1.87h5.254v1.835l.957.223"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M63.484 334.227H58.23a.367.367 0 01-.367-.368v-.004c0-.203.164-.367.367-.367h5.254c.203 0 .364.164.364.367v.004c0 .204-.16.368-.364.368"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M71.242 338.32c-.156-1.523-7.05-2.629-7.05-2.629l-3.223 2.23-3.434-2.23s-6.765 1.133-6.863 2.63c-1.383 7.792-1.621 27.874-1.621 27.874l3.453-.03-.004-21.274.004 24.71h16.789l.008-3.437h3.426s-.043-21.195-1.485-27.844"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M67.594 344.89v-8.585l-2.059-.438v6.375H56.29v-6.375l-2.059.438v8.586H52.5l.004 24.71 2.45 30.212h11.773l2.566-30.211V344.89h-1.7"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M51.734 367.969s.434-.125.602-.688c.25-1.215.164-1.77.164-1.77l.035-6.968H48.5l.336 6.836c-.207 1.375-1.379 2.703-1.29 3.492.54.887 1.415 2.621 2.263 2.703.918-.316 2.156-.887 2.726-2.058-.117-.743-.8-1.547-.8-1.547M70.094 367.969s-.434-.125-.602-.688c-.25-1.215-.164-1.77-.164-1.77l-.035-6.968h4.035l-.336 6.836c.207 1.375 1.38 2.703 1.29 3.492-.54.887-1.415 2.621-2.262 2.703-.918-.316-2.157-.887-2.727-2.058.117-.743.8-1.547.8-1.547"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M72.797 365.375c0 .07-.453.125-1.008.125-.555 0-1.004-.055-1.004-.125s.45-.125 1.004-.125c.555 0 1.008.055 1.008.125M51.063 365.375c0 .07-.45.125-1.004.125-.555 0-1.008-.055-1.008-.125s.453-.125 1.008-.125c.554 0 1.004.055 1.004.125"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#1A1D2E"
                d="M61.262 385.836c0-8.477-.164-15.352-.364-15.352-.203 0-.367 6.875-.367 15.352 0 8.476.164 13.976.367 13.976.2 0 .364-5.5.364-13.976"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M68.445 388.398s-1.476 10.41-.937 10.594c.91.309 2.387.86 3.39 1.235.645.242 1.07.859 1.07 1.546v.602H60.845v-14.238l7.601.261M53.238 388.398s1.48 10.41.942 10.594c-.91.309-2.387.86-3.39 1.235-.645.242-1.071.859-1.071 1.546v.602h11.125v-14.238l-7.606.261"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M61.133 402.375h-.473v-14.238h.473v14.238"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#1A1D2E"
                d="M65.45 351.297h-9.118v-2.313h9.117v2.313"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M64.781 354.648h-7.976v-3.351h7.976v3.351"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#582A1A"
                d="M56.758 327.965c-.637 1.207.789 5.402 1.781 5.402h4.758c.984 0 2.695-4.488 1.82-5.375-1.094-.742-3.828-1.672-3.828-1.672s-3.707.934-4.531 1.645"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FFB7A4"
                d="M57.273 330.402c-.562.114-1.125-.304-1.257-.933-.13-.633.218-1.239.785-1.356.562-.117 1.039 2.168.472 2.29M64.531 330.402c.563.114 1.125-.304 1.254-.933.133-.633-.219-1.239-.781-1.356-.563-.117-1.04 2.168-.473 2.29"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FFB7A4"
                d="M60.93 334.586c4.672 0 3.925-8.016 3.925-8.016s-.195-2.629-3.824-2.629c-3.168 0-3.945 2.692-3.945 2.692s-.86 7.953 3.844 7.953"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#5E3624"
                d="M60.89 331.973c-.292.273-2.347.636-2.347.636l.09-.539c.18-.347.699-.582 1.289-.582h.969v.485"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#5E3624"
                d="M60.89 331.973c.294.273 2.344.636 2.344.636l-.09-.539c-.18-.347-.699-.582-1.285-.582h-.968v.485"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#582A1A"
                d="M56.582 328.188c.379-.086.79.644.79.644l.155-2.781s.157-1.371 1.649-.875c.558 1.144 3.136 1.09 3.617-.012 1.238-.492 1.484.879 1.484.879s.332 3.039.317 2.922c.199-.781.758-.703.758-.703s1.191-5.575-4.391-5.575c-5.578 0-4.39 5.344-4.379 5.5"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#D99C8C"
                d="M61.047 328.625c-1.785 0-2.918-.262-3.633-.563l.05-.875h6.938l.098.907c-.723.289-1.816.531-3.453.531"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#D9D9D9"
                d="M65.441 327.57c.008-.117.012-.246.016-.382h.344s-.09.164-.36.382m-8.949-.078c-.187-.18-.238-.305-.238-.305h.219c.004.106.011.211.02.305"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#4B2416"
                d="M64.5 328.094l-.098-.906h1.055c-.004.136-.008.265-.016.382-.203.16-.504.352-.941.524m-7.086-.031c-.465-.192-.75-.407-.922-.57a6.681 6.681 0 01-.02-.305h.993l-.05.875"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FF892E"
                d="M61.137 321.121h-.45a4.899 4.899 0 00-4.898 4.899v.05h10.242v-.05a4.898 4.898 0 00-4.894-4.899"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M65.79 325.582c-3.255-.8-2.817-2.852-4.048-4.285H60.02c-1.235 1.433-.797 3.484-4.051 4.285h9.82"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FF892E"
                d="M61.727 320.773h-1.66s-.087 4.832-4.626 4.832v.754h10.91v-.754c-4.538 0-4.624-4.832-4.624-4.832"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M66.352 325.996h-10.91v.844h10.91v-.844"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FF892E"
                d="M55.441 326.84s2.762-.414 5.47-.422c2.75-.004 5.44.422 5.44.422 0 .531-2.714 1.223-5.44 1.223-2.735 0-5.47-.696-5.47-1.223"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M46.547 334.633l-3.652 2.86-4.391-2.782 1.473-.277v-2.008h5.558v1.972l1.012.235"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M45.535 332.973h-5.558a.384.384 0 01-.383-.387v-.004c0-.215.172-.387.383-.387h5.558c.211 0 .383.172.383.387v.004a.384.384 0 01-.383.387"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FF892E"
                d="M53.734 337.3c-.164-1.609-7.453-2.777-7.453-2.777l-3.406 2.36-3.629-2.36s-7.152 1.2-7.258 2.778c-1.46 8.238-1.71 29.469-1.71 29.469l3.648-.032-.004-22.488.004 26.121h17.75l.008-3.633h3.62s-.042-22.406-1.57-29.437"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M49.879 344.25v-9.078l-2.176-.461v6.738h-9.777v-6.738l-2.176.46v9.079h-1.828l.004 26.121 2.59 31.942h12.449l2.71-31.942V344.25H49.88"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M33.113 368.645s.457-.133.633-.723c.27-1.29.176-1.875.176-1.875l.035-7.367h-4.262l.352 7.226c-.219 1.457-1.457 2.86-1.363 3.692.574.937 1.492 2.773 2.394 2.859.969-.336 2.277-.937 2.879-2.172-.121-.789-.844-1.64-.844-1.64M52.52 368.645s-.458-.133-.633-.723c-.27-1.29-.172-1.875-.172-1.875l-.04-7.367h4.266l-.355 7.226c.219 1.457 1.457 2.86 1.363 3.692-.57.937-1.492 2.773-2.394 2.859-.97-.336-2.278-.937-2.88-2.172.122-.789.845-1.64.845-1.64"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#20485E"
                d="M55.379 365.902c0 .075-.477.137-1.063.137-.59 0-1.066-.062-1.066-.137 0-.07.477-.129 1.066-.129.586 0 1.063.059 1.063.13M32.402 365.902c0 .075-.476.137-1.062.137-.586 0-1.063-.062-1.063-.137 0-.07.477-.129 1.063-.129s1.062.059 1.062.13"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#1A1D2E"
                d="M43.184 387.535c0-8.96-.172-16.226-.387-16.226-.211 0-.383 7.265-.383 16.226 0 8.961.172 14.777.383 14.777.215 0 .387-5.816.387-14.777"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <g
                clipPath="url(#clip4)"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              >
                <path
                  fill="#3D91A6"
                  d="M50.781 390.242s-1.562 11.008-.996 11.203c.965.328 2.524.907 3.586 1.305.68.254 1.129.906 1.129 1.637v.633H42.742v-15.051l8.04.273"
                ></path>
              </g>
              <g
                clipPath="url(#clip5)"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              >
                <path
                  fill="#3D91A6"
                  d="M34.703 390.242s1.563 11.008.992 11.203c-.96.328-2.523.907-3.582 1.305a1.741 1.741 0 00-1.133 1.637v.633h11.762v-15.051l-8.039.273"
                ></path>
              </g>
              <g
                clipPath="url(#clip6)"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              >
                <path
                  fill="#20485E"
                  d="M43.047 405.02h-.496v-15.051h.496v15.05"
                ></path>
              </g>
              <path
                fill="#1A1D2E"
                d="M47.61 351.023h-9.637v-2.445h9.636v2.445"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#3D91A6"
                d="M46.906 354.566H38.47v-3.543h8.437v3.543"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#582A1A"
                d="M38.422 326.355c-.676 1.274.836 5.711 1.883 5.711h5.03c1.04 0 2.849-4.746 1.923-5.683-1.156-.785-4.043-1.766-4.043-1.766s-3.922.985-4.793 1.738"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FFB7A4"
                d="M38.969 328.93c-.598.12-1.192-.32-1.328-.989-.141-.668.23-1.308.828-1.433.593-.121 1.097 2.297.5 2.422M46.64 328.93c.594.12 1.188-.32 1.329-.989.136-.668-.235-1.308-.828-1.433-.598-.121-1.098 2.297-.5 2.422"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FFB7A4"
                d="M42.832 333.352c4.941 0 4.148-8.473 4.148-8.473s-.203-2.777-4.039-2.777c-3.347 0-4.171 2.847-4.171 2.847s-.91 8.403 4.062 8.403"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#FF8C6E"
                d="M43.84 328.566c0 .29-.445.52-.992.52-.547 0-.993-.23-.993-.52 0-.289.446-.52.993-.52.547 0 .992.231.992.52"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#582A1A"
                d="M42.836 329.836c.039.637-.734.383-1.39.488-.606.094-.801 1.703-.801 1.703s-.497-1.91.042-2.308c.672-.496 2.11-.567 2.149.117"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#582A1A"
                d="M42.84 329.836c-.035.637.734.383 1.394.488.606.094.801 1.703.801 1.703s.492-1.91-.047-2.308c-.672-.496-2.11-.567-2.148.117M38.234 326.59c.403-.09.836.68.836.68l.168-2.938s.164-1.45 1.742-.93c.586 1.215 3.317 1.157 3.82-.007 1.313-.524 1.571.925 1.571.925s.352 3.215.336 3.094c.211-.828.8-.746.8-.746s1.259-5.895-4.64-5.895c-5.898 0-4.64 5.649-4.633 5.817"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#D99C8C"
                d="M42.957 327.05c-1.887 0-3.086-.273-3.84-.59l.051-.929h7.336c.035.32.07.66.101.961-.761.305-1.917.559-3.648.559"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#D9D9D9"
                d="M47.602 325.934a6.19 6.19 0 00.015-.403h.363s-.093.176-.378.403m-9.461-.079c-.196-.19-.25-.324-.25-.324h.23c.004.114.012.223.02.324"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#4B2416"
                d="M46.605 326.492c-.03-.3-.066-.64-.101-.96h1.113c0 .144-.008.28-.015.402-.211.171-.532.375-.997.558m-7.488-.031c-.492-.207-.793-.43-.976-.606a7.635 7.635 0 01-.02-.324h1.047l-.05.93"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M43.05 319.121h-.472a5.178 5.178 0 00-5.18 5.176v.055h10.829v-.055a5.175 5.175 0 00-5.176-5.176"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#7A2E12"
                d="M47.973 323.832c-3.442-.844-2.98-3.012-4.282-4.527h-1.82c-1.305 1.515-.844 3.683-4.281 4.527h10.383"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M43.676 318.75h-1.754s-.094 5.11-4.89 5.11v.8h11.534v-.8c-4.8 0-4.89-5.11-4.89-5.11"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#7A2E12"
                d="M48.566 324.273H37.031v.895h11.535v-.895"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
              <path
                fill="#CF4E1F"
                d="M37.031 325.168s2.918-.441 5.782-.45c2.906-.003 5.753.45 5.753.45 0 .559-2.875 1.293-5.754 1.289-2.89 0-5.78-.734-5.78-1.289"
                transform="matrix(5.30086 0 0 5.4419 25.244 -1726.361)"
              ></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}
