import axios from "axios";
import { useEffect, useState } from "react";

function GetDataSiteMao() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    
    async function getData() {
        try {
            const response = await axios.get('https://petromk.com/api/admin/books');
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }
    
    useEffect(() => {
        getData();
    }, []);
      
    return (
        <>
            {loading ? <p>Loading...</p> : (
                <>
                <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">

                    <url>
                    <loc>https://petromk.com/</loc>
                    </url>
                    <br />
                    {data?.map((book)=>(
                       <url>
                       <loc>https://petromk.com/book/{book.aboutbook[0]}/{book.namebook}</loc>
                       <br />
                       </url> 
                       
                    ))}
                </urlset>
                </>
            )}
        </>
    );
}

export default GetDataSiteMao;
